import { useRef } from 'react'
import { Link } from 'react-router-dom'
import Lottie from 'react-lottie-player'
import { Box, Container, Stack, Text } from '@chakra-ui/react'

import instagramAnimation from 'animations/instagram.json'

export default function GuestFooter() {
  const lottieRef = useRef()

  return (
    <Box pb={{ md: 8 }}>
      <Container
        mt={{ md: 12 }}
        as={Stack}
        py={8}
        bg="#BFDEE1"
        maxW="1440px"
        rounded={{ md: '3xl' }}
      >
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justify="center"
          align="center"
          spacing={8}
          color="black"
        >
          {/* <Text _hover={{ textDecoration: 'underline' }}>
            <Link to="/">Home</Link>
          </Text>
          <Text _hover={{ textDecoration: 'underline' }}>
            <Link to="/about">About</Link>
          </Text>
          <Text _hover={{ textDecoration: 'underline' }}>
            <Link to="/contact">Contact</Link>
          </Text>
          <Text _hover={{ textDecoration: 'underline' }}>
            <Link to="/privacy-and-safety">Privacy &#38; Safety</Link>
          </Text> */}
          {/* <Box
            as="a"
            href="https://www.instagram.com/chess_with_knarik"
            target="_blank"
            onMouseEnter={() => {
              lottieRef?.current.setDirection(1)
              lottieRef?.current.play()
            }}
            onMouseLeave={() => {
              lottieRef?.current.setDirection(-1)
              lottieRef?.current.play()
            }}
          >
            <Lottie
              ref={lottieRef}
              animationData={instagramAnimation}
              style={{ height: '24px' }}
              loop={false}
            />
          </Box> */}
        </Stack>

        <Text pt={4} textAlign="center" fontSize="36">
          We are now{' '}
          <Box
            as="a"
            href="https://chess.ie"
            target="_blank"
            fontWeight="bold"
            _hover={{ textDecoration: 'underline' }}
          >
            CHESS.ie
          </Box>
        </Text>
      </Container>
    </Box>
  )
}
