import { Redirect, useLocation } from 'react-router-dom'

import useAuthStore from 'store/useAuthStore'
import IdleTimer from './IdleTimer'
import { SocketProvider } from 'context/socket-context'
import useConnection from 'store/useShowClosedConnection'

function AuthGuardWithSocketConnection({ children }) {
  const user = useAuthStore((state) => state.user)
  const location = useLocation()
  const isClosed = useConnection((state) => state.isClosed)

  if (!user) {
    return <Redirect to="/" />
  }

  // force users to take signup quiz before using the website
  if (!user.completedSignupQuiz && location.pathname !== '/new-user-quiz') {
    return <Redirect to="/new-user-quiz" />
  }

  // dont allow users to try take the quiz a second time
  if (user.completedSignupQuiz && location.pathname === '/new-user-quiz') {
    return <Redirect to="/settings" />
  }

  if (isClosed) {
    return <Redirect to="/connection-closed" />
  }

  return (
    <SocketProvider>
      <IdleTimer>{children}</IdleTimer>
    </SocketProvider>
  )
}

export default AuthGuardWithSocketConnection
