import { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import HomePage from './pages/Home'
import MainLayout from './layouts/MainLayout'
import GuestLayout from './layouts/GuestLayout'
import AdminLayout from './layouts/AdminLayout'
import SignupQuizLayout from 'layouts/SignupQuizLayout'
import GuestGuard from 'components/Guards/GuestGuard'
import AuthGuard from 'components/Guards/AuthGuard'
import AuthGuardWithSocket from 'components/Guards/AuthGuardWithSocket'

import AdminGuard from 'components/Guards/AdminGuard'

const routesConfig = [
  {
    exact: true,
    layout: GuestLayout,
    path: '/',
    guard: GuestGuard,
    component: HomePage,
  },
  {
    exact: true,
    layout: GuestLayout,
    path: '/about',
    guard: GuestGuard,
    component: lazyRetry(() => import('pages/About')),
  },
  {
    exact: true,
    layout: GuestLayout,
    path: '/contact',
    guard: GuestGuard,
    component: lazyRetry(() => import('pages/Contact')),
  },
  {
    exact: true,
    layout: GuestLayout,
    path: '/privacy-and-safety',
    guard: GuestGuard,
    component: lazyRetry(() => import('pages/PrivacyPolicy')),
  },
  {
    exact: true,
    path: '/login',
    guard: GuestGuard,
    component: lazyRetry(() => import('pages/Auth/Login')),
  },
  {
    exact: true,
    path: '/signup',
    guard: GuestGuard,
    component: lazyRetry(() => import('pages/Auth/Signup')),
  },
  {
    exact: false,
    path: '/success',
    layout: GuestLayout,
    guard: GuestGuard,
    component: lazyRetry(() => import('pages/Success')),
  },
  {
    exact: true,
    path: '/reset-password',
    guard: GuestGuard,
    component: lazyRetry(() => import('pages/Auth/ForgotPassword')),
  },
  {
    exact: true,
    path: '/forgot-username',
    guard: GuestGuard,
    component: lazyRetry(() => import('pages/Auth/ForgotUsername')),
  },
  {
    exact: true,
    path: '/new-user-quiz',
    guard: AuthGuard,
    layout: SignupQuizLayout,
    component: lazyRetry(() => import('pages/NewUserQuiz')),
  },
  {
    exact: true,
    path: '/connection-closed',
    guard: AuthGuard,
    layout: SignupQuizLayout,
    component: lazyRetry(() => import('pages/ConnectionClosed')),
  },
  {
    path: '/',
    guard: AuthGuardWithSocket,
    layout: MainLayout,
    routes: [
      // {
      //   exact: true,
      //   path: '/schedule',
      //   component: lazyRetry(() => import('pages/Schedule')),
      //   loader: true,
      // },
      {
        exact: true,
        path: '/messages',
        component: lazyRetry(() => import('pages/Messages')),
        loader: true,
      },
      {
        exact: true,
        path: '/live-class',
        component: lazyRetry(() => import('pages/LiveClass')),
        loader: true,
      },
      {
        exact: true,
        path: '/premium-signup-error',
        component: lazyRetry(() => import('pages/Auth/SignupPremiumError')),
        loader: true,
      },
      {
        exact: true,
        path: '/premium-signup',
        component: lazyRetry(() => import('pages/Auth/SignupPremium')),
        loader: true,
      },
      {
        exact: true,
        path: '/upgrade-account',
        component: lazyRetry(() => import('pages/UpgradeAccount')),
        loader: true,
      },
      {
        exact: true,
        path: '/upgrade-account-success',
        component: lazyRetry(() => import('pages/UpgradeAccountSuccess')),
        loader: true,
      },
      {
        exact: true,
        path: '/my-kids',
        component: lazyRetry(() => import('pages/ChildAccounts')),
        loader: true,
      },
      {
        exact: true,
        path: '/add-child',
        component: lazyRetry(() => import('pages/CreateChildAccount')),
        loader: true,
      },
      {
        exact: false,
        path: '/puzzles',
        component: lazyRetry(() => import('pages/Puzzles')),
        loader: true,
      },
      {
        exact: true,
        path: '/lessons',
        component: lazyRetry(() => import('pages/Lessons')),
        loader: true,
      },
      {
        exact: true,
        path: '/lessons/:id',
        component: lazyRetry(() => import('pages/Lesson')),
        loader: true,
      },
      {
        path: '/practice',
        component: lazyRetry(() => import('pages/Practice')),
        loader: true,
      },
      {
        exact: true,
        path: '/play',
        component: lazyRetry(() => import('pages/Play/PlayHomePage')),
      },
      {
        exact: true,
        path: '/play-computer',
        component: lazyRetry(() => import('pages/PlayComputer')),
      },
      {
        exact: true,
        path: '/play-online/:id',
        component: lazyRetry(() => import('pages/Play/GamePage')),
      },
      {
        exact: true,
        path: '/play-tournament/:tournamentId/:id',
        component: lazyRetry(() => import('pages/Play/GamePage')),
      },
      {
        exact: true,
        path: '/tournaments',
        component: lazyRetry(() => import('pages/Tournament')),
      },
      {
        exact: true,
        path: '/watch',
        component: lazyRetry(() => import('pages/Watch')),
      },
      {
        exact: true,
        path: '/settings',
        component: lazyRetry(() => import('pages/Settings')),
      },
      {
        exact: true,
        path: '/panic-report',
        component: lazyRetry(() => import('pages/PanicReports')),
      },
      {
        exact: true,
        path: '/user/:userId/profile',
        component: lazyRetry(() => import('pages/UserProfile')),
      },
      {
        path: '/admin',
        guard: AdminGuard,
        layout: AdminLayout,
        routes: [
          {
            exact: true,
            path: '/admin/dashboard',
            component: lazyRetry(() => import('pages/Admin/Dashboard')),
          },
          {
            exact: true,
            path: '/admin/questions',
            component: lazyRetry(() => import('pages/Admin/Questions')),
          },
          {
            exact: true,
            path: '/admin/users',
            component: lazyRetry(() => import('pages/Admin/Users')),
          },
          {
            exact: true,
            path: '/admin/users/:id',
            component: lazyRetry(() => import('pages/Admin/User')),
          },
          {
            exact: true,
            path: '/admin/lessons',
            component: lazyRetry(() => import('pages/Admin/Lessons')),
          },
          {
            exact: true,
            path: '/admin/lessons/edit/:id',
            component: lazyRetry(() => import('pages/Admin/EditLesson')),
          },
          {
            exact: true,
            path: '/admin/exercises',
            component: lazyRetry(() => import('pages/Admin/Exercises')),
          },
          {
            exact: true,
            path: '/admin/rewards',
            component: lazyRetry(() => import('pages/Admin/Rewards')),
          },
          {
            exact: true,
            path: '/admin/tournaments',
            component: lazyRetry(() => import('pages/Admin/Tournaments')),
          },
          {
            exact: true,
            path: '/admin/panic-reports',
            component: lazyRetry(() => import('pages/Admin/PanicReports')),
          },
          {
            // exact: true,
            path: '/admin/classes',
            component: lazyRetry(() => import('pages/Admin/Webinars')),
          },
          {
            exact: true,
            path: '/admin/class-board',
            component: lazyRetry(() => import('pages/Admin/ClassBoard')),
          },

          {
            exact: true,
            path: '/admin/promo-codes',
            component: lazyRetry(() => import('pages/Admin/PromoCodes')),
          },
          {
            component: () => <Redirect to="/" />,
          },
        ],
      },
      {
        path: '/question',
        guard: AdminGuard,
        routes: [
          {
            exact: true,
            path: '/question/preview',
            component: lazyRetry(() => import('pages/PreviewQuestion')),
          },

          {
            component: () => <Redirect to="/" />,
          },
        ],
      },
      {
        component: () => <Redirect to="/" />,
      },
    ],
  },
  {
    component: () => <Redirect to="/" />,
  },
]

function lazyRetry(component, retries, interval) {
  return lazy(() => retry(component, retries, interval))
}

function retry(fn, retriesLeft = 5, interval = 1000, backoff = 1.5) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        if (retriesLeft === 1) {
          reject(error)
          return
        }

        const nextInterval = interval * backoff
        setTimeout(() => {
          retry(fn, retriesLeft - 1, nextInterval, backoff).then(
            resolve,
            reject,
          )
        }, interval)
      })
  })
}

export default routesConfig
