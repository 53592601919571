import { useState, useEffect } from 'react'
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { ImWarning } from 'react-icons/im'

import { useSocket } from 'context/socket-context'

const TournamentOverModal = ({}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [leaderboard, setLeaderboard] = useState()
  const socket = useSocket()

  useEffect(() => {
    socket.on('tournamentOver', setLeaderboard)

    return () => {
      socket.off('tournamentOver', setLeaderboard)
    }
  }, [])

  useEffect(() => {
    if (leaderboard) {
      onOpen()
    } else {
      onClose()
    }
  }, [leaderboard])

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent overflow="hidden">
        <ModalHeader color="green.500" bg="green.100" fontWeight="extrabold">
          Tournament Over
        </ModalHeader>
        <ModalCloseButton color="green.300" mt={2} />
        <ModalBody py={8} px={2} bg="green.50">
          <Body leaderboard={leaderboard} />
        </ModalBody>
        <ModalFooter bg="green.50">
          <Button onClick={onClose} colorScheme="orange">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const Body = ({ leaderboard }) => {
  if (!leaderboard || leaderboard.length === 0) {
    return (
      <Flex
        align="center"
        color="red.500"
        fontSize="lg"
        fontWeight="bold"
        px={2}
      >
        <ImWarning />
        <Text ml={2}>No leaderboard data available.</Text>
      </Flex>
    )
  }

  if (leaderboard.length < 3) {
    return (
      <Flex align="center" direction="column">
        <Text
          fontSize="lg"
          color="green.600"
          textAlign="center"
          fontWeight="semibold"
        >
          Congratulations to the winner
        </Text>
        <Flex align="flex-end">
          <Image
            h={10}
            src={`/images/celebration.svg`}
            transform="rotate(-90deg)"
          />
          <Avatar mt={4} size="xl" src={leaderboard[0].user.avatar} />
          <Image h={10} src={`/images/celebration.svg`} />
        </Flex>
        <Box bg="green.100" px={2} rounded="md" mt={-1.5} zIndex={9}>
          <Text fontSize="xl" fontWeight="bold" color="green.700">
            {leaderboard[0].user.username}
          </Text>
        </Box>
      </Flex>
    )
  }

  const winner = leaderboard[0]
  const second = leaderboard[1]
  const third = leaderboard[2]

  return (
    <Box>
      <Text
        fontSize="lg"
        color="green.600"
        textAlign="center"
        fontWeight="semibold"
      >
        {' '}
        Congratulations to the winner {winner.user.username}
      </Text>
      <Flex align="flex-end" justify="center" mt={8}>
        <Box w="33%">
          <Flex justify="center">
            <Avatar src={second.user.avatar} />
          </Flex>

          <Tooltip label={second.user.username}>
            <Text
              fontSize="sm"
              fontWeight="semibold"
              textAlign="center"
              color="green.700"
              lineHeight={1}
              isTruncated
            >
              {second.user.username}
            </Text>
          </Tooltip>
          <Flex
            mt={2}
            h="70px"
            bg="green.300"
            justify="center"
            align="flex-end"
            roundedTop="md"
          >
            <Text fontWeight="extrabold" fontSize="3xl" color="green.100">
              2
            </Text>
          </Flex>
        </Box>
        <Box w="33%">
          <Flex justify="center" align="flex-end">
            <Image
              h={10}
              src={`/images/celebration.svg`}
              transform="rotate(-90deg)"
            />
            <Avatar size="lg" src={winner.user.avatar} mx={2} />
            <Image h={10} src={`/images/celebration.svg`} />
          </Flex>
          <Tooltip label={winner.user.username}>
            <Text
              fontSize="sm"
              fontWeight="semibold"
              textAlign="center"
              color="green.700"
              lineHeight={1}
              isTruncated
            >
              {winner.user.username}
            </Text>
          </Tooltip>

          <Flex
            mt={2}
            h="100px"
            bg="yellow.300"
            justify="center"
            align="flex-end"
            roundedTop="md"
          >
            <Text
              fontWeight="extrabold"
              fontSize="6xl"
              color="white"
              lineHeight={1.1}
            >
              1
            </Text>
          </Flex>
        </Box>
        <Box w="33%">
          <Flex justify="center">
            <Avatar src={third.user.avatar} />
          </Flex>
          <Tooltip label={third.user.username}>
            <Text
              fontSize="sm"
              fontWeight="semibold"
              textAlign="center"
              color="green.700"
              lineHeight={1}
              isTruncated
            >
              {third.user.username}
            </Text>
          </Tooltip>
          <Flex
            mt={2}
            h="50px"
            bg="orange.300"
            justify="center"
            align="flex-end"
            roundedTop="md"
          >
            <Text fontWeight="extrabold" fontSize="3xl" color="orange.100">
              3
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export default TournamentOverModal
