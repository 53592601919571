import { useState } from 'react'
import { useQuery, useQueryCache } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'lib/axios'
import { useSocket } from 'context/socket-context'

const getChallenges = async () => {
  const { data } = await axios.get('/api/chess/challenges')
  return data
}

export const useChallenges = () => {
  return useQuery('challenges', getChallenges)
}

export const useAcceptChallenge = () => {
  const toast = useToast()
  const socket = useSocket()
  const queryCache = useQueryCache()
  const [status, setStatus] = useState('idle')

  const acceptChallenge = (challenge) => {
    if (socket.connected) {
      setStatus('loading')
      socket.emit('acceptChallenge', challenge, (res) => {
        if (res.error) {
          toast({
            status: 'error',
            title: 'Unable to accept challenge',
            description: res.error,
          })
        } else {
          queryCache.invalidateQueries()
          toast.closeAll()
        }

        setStatus('idle')
      })
    } else {
      alert('Unable to accept, you are disconnected!')
    }
  }

  return { acceptChallenge, status }
}

export const useDeclineChallenge = () => {
  const toast = useToast()
  const socket = useSocket()
  const queryCache = useQueryCache()
  const [status, setStatus] = useState('idle')

  const declineChallenge = (challenge) => {
    if (socket.connected) {
      setStatus('loading')
      socket.emit('declineChallenge', challenge, (res) => {
        if (res.error) {
          toast({
            status: 'error',
            title: 'Unable to decline challenge',
            description: res.error,
          })
        }

        queryCache.invalidateQueries()
        setStatus('idle')
      })
    } else {
      alert('Unable to decline, you are disconnected!')
    }
  }

  return { declineChallenge, status }
}

export const useCancelChallenge = () => {
  const toast = useToast()
  const queryCache = useQueryCache()
  const [status, setStatus] = useState('idle')

  const cancelChallenge = async (id) => {
    try {
      setStatus('loading')
      const { data } = await axios.delete(`/api/chess/challenges/${id}`)
      toast({
        status: 'success',
        title: data.message,
        duration: 2000,
      })
    } catch (err) {
      toast({
        status: 'error',
        title: 'Unable to cancel challenge',
        description: err.response?.data?.error || err.message,
      })
    } finally {
      queryCache.invalidateQueries()
      setStatus('idle')
    }
  }

  return { cancelChallenge, status }
}
