import {
  useEffect,
  useState,
  useRef,
  createContext,
  useContext,
  createRef,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryCache } from 'react-query'
import { io } from 'socket.io-client'
import { useToast } from '@chakra-ui/react'

import TournamentAlert from 'components/Tournament/TournamentAlert'
import NextRoundCountdown from 'components/Tournament/NextRoundCountdown'
import RegisteredByAdmin from 'components/Tournament/RegisteredByAdmin'
import useAuthStore from 'store/useAuthStore'
import useOnlineUsers from 'store/useOnlineUsers'
import useOnlinePlayerDrawer from 'store/useOnlinePlayerDrawer'
import useConnection from 'store/useShowClosedConnection'
import Connecting from 'pages/ConnectingToSocket'
import ChallengeNotification from 'components/challenges/ChallengeNotification'
import ChallengeResponse from 'components/challenges/ChallengeResponse'
import { soundEffects } from 'audio/soundEffects'

// TODO refactor listeners to seperate component
const SocketContext = createContext({})

function SocketProvider({ children }) {
  const socket = useRef({})

  const cache = useQueryCache()
  const countdownToastRef = useRef()
  const user = useAuthStore((state) => state.user)
  const logout = useAuthStore((state) => state.logout)
  const updateUserScore = useAuthStore((state) => state.updateUserScore)
  const [isConnecting, setIsConnecting] = useState(true)
  const close = useConnection((state) => state.close)

  const updateOnlineUsers = useOnlineUsers((state) => state.updateOnlineUsers)
  const closeOnlinePlayerDrawer = useOnlinePlayerDrawer(
    (state) => state.onClose,
  )

  const declineChallenge = (challenge, cb) => {
    if (socket.current?.connected) {
      socket.current.emit('declineChallenge', challenge, (res) => {
        if (res.error) {
          toast({
            status: 'error',
            title: 'Unable to decline challenge',
            description: res.error,
          })
        }
        cb()
      })
    } else {
      alert('Unable to decline, you are disconnected!')
    }
  }

  const history = useHistory()
  const toast = useToast()

  const acceptChallenge = (challenge, cb) => {
    if (socket.current?.connected) {
      socket.current?.emit('acceptChallenge', challenge, (res) => {
        if (res.error) {
          toast({
            status: 'error',
            title: 'Unable to accept challenge',
            description: res.error,
          })
        } else {
          toast.closeAll()
        }

        cb()
      })
    } else {
      alert('Unable to accept, you are disconnected!')
    }
  }

  const onStartGame = (data) => {
    closeOnlinePlayerDrawer()

    if (data.message) {
      toast({
        title: data.message,
        status: 'info',
        duration: 4000,
        isClosable: true,
      })
    }

    if (data.tid) {
      history.push(`/play-tournament/${data.tid}/${data.id}`, data)
    } else {
      history.push(`/play-online/${data.id}`, data)
    }
  }

  const onRatingUpdate = (rating) => {
    updateUserScore(rating)
  }

  const closeCountdown = () => {
    if (countdownToastRef.current) {
      toast.close(countdownToastRef.current)
    }
  }

  const onChallengeDeclined = (data) => {
    countdownToastRef.current = toast({
      position: 'bottom-right',
      render: () => (
        <ChallengeResponse
          challenge={data}
          status="declined"
          onClose={closeCountdown}
        />
      ),
    })
  }

  const onChallengeAccepted = (data) => {
    countdownToastRef.current = toast({
      position: 'bottom-right',
      render: () => (
        <ChallengeResponse
          challenge={data}
          status="accepted"
          onClose={closeCountdown}
        />
      ),
    })
  }

  const onNewTournamentAnnouncement = (tournament) => {
    toast({
      position: 'bottom',
      render: () => <TournamentAlert tournament={tournament} />,
    })
  }

  const onRoundOver = (data) => {
    countdownToastRef.current = toast({
      position: 'bottom',
      duration: data.timeUntilNextRound * 1000,
      render: () => (
        <NextRoundCountdown
          countdown={data.timeUntilNextRound}
          onClose={closeCountdown}
        />
      ),
    })
  }

  const onChallenge = (data) => {
    soundEffects.challenge()

    const challengeRef = createRef()
    challengeRef.current = toast({
      position: 'bottom-right',
      duration: null,
      render: () => (
        <ChallengeNotification
          challenge={data}
          onAccept={acceptChallenge}
          onDecline={declineChallenge}
          onClose={() => toast.close(challengeRef.current)}
        />
      ),
    })
  }

  const onRegisteredByAdmin = (data) => {
    toast({
      position: 'bottom',
      duration: 4000,
      render: () => (
        <RegisteredByAdmin
          admin={data.admin}
          text={`Registered you for the ${data.tournament} tournament.`}
        />
      ),
    })
  }

  const onUnRegisteredByAdmin = (data) => {
    toast({
      position: 'bottom',
      duration: 4000,
      render: () => (
        <RegisteredByAdmin
          admin={data.admin}
          text={`Removed you form the ${data.tournament} tournament.`}
        />
      ),
    })
  }

  const onIvalidateQuery = () => {
    cache.invalidateQueries('live-class')
    cache.invalidateQueries('user-classes')
  }

  const connectSocket = () => {
    setIsConnecting(true)
    socket.current = io(process.env.REACT_APP_SOCKET_CONNECTION, {
      query: { userId: user?.userId },
    })

    socket.current.on('connect', () => {
      socket.current.emit('userConnected', user)
      setIsConnecting(false)
    })
    socket.current.on('onlineUserCount', updateOnlineUsers)
    socket.current.on('close-connection', close)
    socket.current.on('startGame', onStartGame)
    socket.current.on('challengeDecline', onChallengeDeclined)
    socket.current.on('challengeAccepted', onChallengeAccepted)
    socket.current.on('new-tournament', onNewTournamentAnnouncement)
    socket.current.on('tRegisteredByAdmin', onRegisteredByAdmin)
    socket.current.on('tUnregisteredByAdmin', onUnRegisteredByAdmin)
    socket.current.on('invalidate-live-class-query', onIvalidateQuery)
    socket.current.on('ratingUpdate', onRatingUpdate)
    socket.current.on('invalidateQueryCache', () => cache.invalidateQueries())
    socket.current.on('roundOver', onRoundOver)
    socket.current.on('offerChallenge', onChallenge)
    socket.current.on('forceLogout', logout)
    socket.current.on('ping', (cb) => cb('pong'))
  }

  const disconnectSocket = () => {
    if (socket.current?.connected) {
      socket.current.disconnect()
    }
  }

  useEffect(() => {
    connectSocket()

    return () => {
      disconnectSocket()
    }
  }, [])

  return (
    <SocketContext.Provider value={socket.current}>
      {!isConnecting && children}
      {isConnecting && <Connecting />}
    </SocketContext.Provider>
  )
}

function useSocket() {
  const context = useContext(SocketContext)

  if (context === undefined) {
    throw new Error('useSocket must be used within a SocketProvider')
  }

  return context
}

export { SocketProvider, useSocket }
