import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Box, Flex, Input, Text } from '@chakra-ui/react'

import axios from 'lib/axios'

export default function SearchPanel({ closeDrawer }) {
  const [value, setValue] = useState('')
  const [users, setUsers] = useState([])

  const userSearch = async (search) => {
    try {
      const response = await axios.get(`/api/chess/users?search=${search}`)
      setUsers(response.data)
    } catch (err) {
      alert('Error searching for user')
      setUsers([])
    }
  }

  useEffect(() => {
    if (value.trim().length < 3) {
      return setUsers([])
    }

    const timeoutId = setTimeout(() => {
      userSearch(value)
    }, 300)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [value])

  return (
    <Box>
      <Box>
        <Input
          bg="white"
          rounded="none"
          placeholder="Search for a player"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Box>
      <Box>
        {users.map((user) => (
          <Flex
            key={user.id}
            py={4}
            px={8}
            borderTopWidth={1}
            borderColor="green.200"
            justify="space-between"
            align="center"
            _last={{
              borderBottomWidth: 1,
            }}
          >
            <Flex direction="column" align="flex-start" justify="center">
              <Box position="relative">
                <Avatar
                  onClick={closeDrawer}
                  as={Link}
                  to={`/user/${user.id}/profile`}
                  size={'lg'}
                  src={user.avatar}
                  borderWidth={2}
                  borderColor="green.300"
                />
              </Box>

              <Box
                px={2}
                rounded="md"
                color="gray.50"
                bg="gray.400"
                fontSize="sm"
                minW="64px"
                textAlign="center"
                fontWeight="bold"
              >
                {user.username}
              </Box>
            </Flex>
            <Box
              bg={user.isOnline ? 'green.200' : 'red.100'}
              p={2}
              rounded="md"
            >
              <Text
                fontWeight="bold"
                textTransform="uppercase"
                color={user.isOnline ? 'green.600' : 'red.500'}
              >
                {user.isOnline ? 'Online' : 'Offline'}
              </Text>
            </Box>
          </Flex>
        ))}
      </Box>
    </Box>
  )
}
