import axios from 'lib/axios'

export const getFriends = async () => {
  const { data } = await axios.get('/api/chess/friends')
  return data
}

export const getUsersFriends = async (userId) => {
  const { data } = await axios.get(`/api/chess/friends/user/${userId}`)
  return data
}

export const getUsersFriendsStatus = async (userId1, userId2) => {
  const { data } = await axios.get(
    `/api/chess/friends/check/${userId1}/${userId2}`,
  )
  return data
}

export const unfriend = async (userId) => {
  const { data } = await axios.delete(`/api/chess/friends/${userId}`)
  return data
}

export const sendFriendRequest = async (userId) => {
  const { data } = await axios.post('/api/chess/friends/requests', { userId })
  return data
}

export const getPendingFriendRequests = async () => {
  const { data } = await axios.get('/api/chess/friends/requests')
  return data
}

export const acceptFriendRequest = async (id) => {
  const { data } = await axios.patch(`/api/chess/friends/requests/${id}/accept`)
  return data
}

export const declineFriendRequest = async (id) => {
  const { data } = await axios.patch(
    `/api/chess/friends/requests/${id}/decline`,
  )
  return data
}

export const cancelFriendRequest = async (id) => {
  const { data } = await axios.patch(`/api/chess/friends/requests/${id}/cancel`)
  return data
}
