import axios from 'lib/axios'

function getEventColor(event) {
  if (event.isLessonRequest) {
    return 'red'
  } else if (event.type === 'open') {
    return event.isPremium ? 'orange' : 'blue'
  } else if (event.type === 'private') {
    return 'green'
  }
}

export const getCalendar = async () => {
  const response = await axios.get('/api/chess/classes/calendar')

  return response.data.map((event) => {
    const color = getEventColor(event)

    return {
      ...event,
      start: new Date(event.start),
      color: color,
      borderColor: `var(--chakra-colors-${color}-500)`,
      textColor: `var(--chakra-colors-${color}-50)`,
      backgroundColor: `var(--chakra-colors-${color}-400)`,
    }
  })
}

export const scheduleClass = async (classDetails) => {
  const response = await axios.post('/api/chess/classes', classDetails)
  return response.data
}

export const getClasses = async (status) => {
  const qs = status ? `?status=${status}` : ''
  const response = await axios.get(`/api/chess/classes${qs}`)
  return response.data
}

export const getScheduledClass = async (id) => {
  const response = await axios.get(`/api/chess/classes/${id}`)
  return response.data
}

export const getClassRequests = async (status) => {
  let qs = status ? `?status=${status}` : ''
  const response = await axios.get(`/api/chess/classes/requests${qs}`)
  return response.data
}

export const requestPrivateClass = async (data) => {
  const response = await axios.post('/api/chess/classes/requests', data)
  return response.data
}

export const getUserPendingRequests = async () => {
  const response = await axios.get('/api/chess/classes/user/requests/pending')
  return response.data
}

export const deletePrivateClassRequest = async (id) => {
  const response = await axios.delete(`/api/chess/classes/user/requests/${id}`)
  return response.data
}

export const deleteClass = async (id) => {
  const response = await axios.delete(`/api/chess/classes/${id}`)
  return response.data
}

export const updateClass = async (id, updates) => {
  const response = await axios.patch(`/api/chess/classes/${id}`, updates)
  return response.data
}

export const getUserClasses = async (statusArr) => {
  const qs = statusArr ? `?status=${statusArr.join(',')}` : ''
  const response = await axios.get(`/api/chess/classes/user${qs}`)
  return response.data
}

export const getUserLiveClass = async () => {
  const response = await axios.get('/api/chess/classes/user/live')
  return response.data
}

export const getStartSignature = async (id) => {
  const response = await axios.get(`api/chess/classes/start-signature?id=${id}`)
  return response.data
}

export const getPrivateVideoSignature = async () => {
  const response = await axios.get(
    `api/chess/classes/private-meeting-start-signature`,
  )
  return response.data
}

export const getJoinSignature = async (id) => {
  const response = await axios.get(`/api/chess/classes/join-signature?id=${id}`)
  return response.data
}

export const getClassRegistrants = async (id) => {
  const response = await axios.get(`/api/chess/classes/${id}/registered`)
  return response.data
}

export const registerForClass = async (id) => {
  const response = await axios.post(`/api/chess/classes/${id}/register`)
  return response.data
}

export const unregisterForClass = async (id) => {
  const response = await axios.post(`/api/chess/classes/${id}/unregister`)
  return response.data
}

export const updateClassRequestStatus = async (id, status) => {
  const response = await axios.patch(
    `/api/chess/classes/requests/${id}?status=${status}`,
    {},
  )
  return response.data
}

export const emailUser = async (data) => {
  const response = await axios.post('/api/chess/classes/email-user', {
    to: data.to,
    userId: data.userId,
    subject: data.subject,
    message: data.message,
  })
  return response.data
}

export const trackClassAttendance = async (id) => {
  return axios.post(`/api/chess/classes/${id}/join`)
}
