import { remove } from 'lodash'
import mdToTxt from 'markdown-to-txt'
import removeChessMarkdown from './removeChessMd'

const ytRegex =
  /\s(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/g

// remove encoded chess moves, and markdown before converting
// to audio file using polly
// const toPollyString = (str) => {
//   const withoutYoutubeUrls = str.replaceAll(ytRegex, '')

//   const text = mdToTxt(removeChessMarkdown(withoutYoutubeUrls), {
//     escapeHtml: false,
//   })

//   // update custom breath command to correct polly format
//   const pollyText = text.replaceAll('<breath', '<amazon:breath')

//   return `<speak>${pollyText}</speak>`
// }

const toPollyString = (str) => {
  const withoutYoutubeUrls = str.replace(new RegExp(ytRegex, 'g'), '')

  const text = mdToTxt(removeChessMarkdown(withoutYoutubeUrls), {
    escapeHtml: false,
  })

  // update custom breath command to correct polly format
  const pollyText = text.replace(/<breath/g, '<amazon:breath')

  return `<speak>${pollyText}</speak>`
}

function removeAllBreakTags(text) {
  return text.replace(/<break[^>]*>/g, '')
}

// remove custom chess markdown, normal markdown and polly break tags
// to get an estimated length
// export const pollyStringLengthEst = (str) => {
//   const withoutYoutubeUrls = str.replaceAll(ytRegex, '')

//   const text = mdToTxt(removeChessMarkdown(withoutYoutubeUrls), {
//     escapeHtml: false,
//   })

//   return removeAllBreakTags(text).trim().length
// }

export const pollyStringLengthEst = (str) => {
  const withoutYoutubeUrls = str.replace(new RegExp(ytRegex, 'g'), '')

  const text = mdToTxt(removeChessMarkdown(withoutYoutubeUrls), {
    escapeHtml: false,
  })

  const withoutBreakTags = removeAllBreakTags(text)
  const trimmedText = withoutBreakTags.trim()

  return trimmedText.length
}

export default toPollyString
