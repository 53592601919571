import { useQuery, useMutation, useQueryCache } from 'react-query'
import { useToast } from '@chakra-ui/react'

import {
  getPendingFriendRequests,
  acceptFriendRequest,
  declineFriendRequest,
  cancelFriendRequest,
} from 'services/friends'

export const useGetPendingFriends = () => {
  return useQuery('pending-friends-requests', getPendingFriendRequests)
}

export const useAcceptFriendRequest = (onSuccess) => {
  const toast = useToast()
  const queryCache = useQueryCache()

  return useMutation(acceptFriendRequest, {
    onSuccess: (data) => {
      queryCache.invalidateQueries()
      toast({
        status: 'success',
        title: data.message,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    onError: (error) => {
      toast({
        status: 'error',
        title:
          error?.response?.data?.error || 'Unable to accept friend request',
      })
    },
  })
}

export const useDeclineFriendRequest = (onSuccess) => {
  const toast = useToast()
  const queryCache = useQueryCache()

  return useMutation(declineFriendRequest, {
    onSuccess: (data) => {
      queryCache.invalidateQueries()
      toast({
        status: 'success',
        title: data.message,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    onError: (error) => {
      toast({
        status: 'error',
        title:
          error?.response?.data?.error || 'Unable to decline friend request',
      })
    },
  })
}

export const useCancelFriendRequest = (onSuccess) => {
  const toast = useToast()
  const queryCache = useQueryCache()

  return useMutation(cancelFriendRequest, {
    onSuccess: (data) => {
      queryCache.invalidateQueries()
      toast({
        status: 'success',
        title: data.message,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    onError: (error) => {
      toast({
        status: 'error',
        title:
          error?.response?.data?.error || 'Unable to cancel friend request',
      })
    },
  })
}
