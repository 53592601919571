const questionMdChessRegex =
  /\[\s*([a-h]\d\s*),\s*([a-h]\d)\s*(?:,\s*([qrbnQRBN]))?\s*\]|\[(-|[a-h]\d)\]|\[(\[[a-h]\d-[a-h]\d])*\]|\[([rnbqkpRNBQKP0-8/]*\s[\w\s-]+)\]/g

const lessonChessMdRegex =
  /\[board-(\d)\](\[\s*([a-h]\d\s*),\s*([a-h]\d)\s*(?:,\s*([rbnqRBNQ])\s*)?\]|\[(-|[a-h]\d-[a-zA-Z]*)\]|\[(\[[a-h]\d-[a-h]\d])*\]|\[([rnbqkpRNBQKP0-8/]*\s[\w\s-]+)\]|\[(-|[a-h|[1-8]-[a-zA-Z]*)\]|\[(-|(?:[a-h][1-8]\.)*[a-h][1-8]-[a-zA-Z]*)\])/g

const lessonBoard = /chessboard-\d*/g

// returns a string without chess question markdown and chess lesson markdown
// const removeChessMarkdown = (str) => {
//   return str
//     .replaceAll(lessonChessMdRegex, '')
//     .replaceAll(questionMdChessRegex, '')
//     .replaceAll(lessonBoard, '')
//     .replace(/\s{2,}/g, ' ')
// }

const removeChessMarkdown = (str) => {
  return str
    .replace(new RegExp(lessonChessMdRegex, 'g'), '')
    .replace(new RegExp(questionMdChessRegex, 'g'), '')
    .replace(new RegExp(lessonBoard, 'g'), '')
    .replace(/\s{2,}/g, ' ')
}

export default removeChessMarkdown
