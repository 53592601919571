import { Redirect } from 'react-router-dom'
import useAuthStore from 'store/useAuthStore'

function AdminGuard({ children }) {
  const user = useAuthStore((state) => state.user)

  if (!user) {
    return <Redirect to="/" />
  }

  if (!user.isAdmin) {
    return <Redirect to="/puzzles/quiz" />
  }

  return children
}

export default AdminGuard
