import { useRef, useEffect } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useIdleTimer } from 'react-idle-timer'
import useCountDown from 'react-countdown-hook'
import { GiSandsOfTime } from 'react-icons/gi'

import useAuthStore from 'store/useAuthStore'
import { soundEffects } from 'audio/soundEffects'

function IdleTimer({ children }) {
  const promptTimeout = useRef(1000 * 60)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const logout = useAuthStore((state) => state.logout)

  // close modal and log user out
  const handleOnIdle = () => {
    onClose()
    logout()
  }

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 20,
    promptTimeout: promptTimeout.current,
    onPrompt: onOpen,
    onIdle: handleOnIdle,
    debounce: 500,
  })

  return (
    <>
      {children}
      <InactiveAlert
        isOpen={isOpen}
        onClose={onClose}
        remainingTime={promptTimeout.current}
        reset={reset}
      />
    </>
  )
}

export default IdleTimer

function InactiveAlert({ isOpen, onClose, remainingTime, reset }) {
  const cancelRef = useRef()
  const [timeLeft, actions] = useCountDown(remainingTime, 1000)

  const handleStayLoggedIn = () => {
    reset()
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      soundEffects.alert()
      actions.start()
    } else {
      actions.reset()
    }
  }, [isOpen])

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="blue.100" overflow="hidden">
            <AlertDialogHeader bg="blue.200">
              <Flex align="center" color="blue.700">
                <GiSandsOfTime />
                <Text ml={2} fontSize="xl" fontWeight="extrabold">
                  Inactivity Alert
                </Text>
              </Flex>
            </AlertDialogHeader>

            <AlertDialogBody mt={4}>
              <Text color="blue.700">
                Are you still here? You will be logged out in {timeLeft / 1000}{' '}
                seconds.
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter bg="blue.100">
              <Button
                colorScheme="green"
                ref={cancelRef}
                onClick={handleStayLoggedIn}
              >
                Stay Logged In
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
