import create from 'zustand'

// used to display live class nav option for students in a live class
const useLiveClass = create((set, get) => ({
  isLive: false,
  setIsLive: (value) => set({ isLive: value }),
  studentJoined: 0,
  onJoined: () => set({ studentJoined: get().studentJoined + 1 }),
}))

export default useLiveClass
