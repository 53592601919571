import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  SlideFade,
  Tooltip,
} from '@chakra-ui/react'

import useLiveClass from 'store/useLiveClass'
import { getUserLiveClass } from 'services/scheduledClasses'
import { useJoinLiveClass } from 'hooks/useJoinLiveClass'

const LiveClassAlert = () => {
  const {
    isLoading,
    error,
    data: liveClass,
  } = useQuery('live-class', getUserLiveClass)

  const { isConnecting, joinLiveClass } = useJoinLiveClass()

  const setIsLive = useLiveClass((state) => state.setIsLive)
  const onJoined = useLiveClass((state) => state.onJoined)
  const studentJoined = useLiveClass((state) => state.studentJoined)
  const [isVisible, setIsVisible] = useState(true)

  const hideAlert = () => setIsVisible(false)

  useEffect(() => {
    if (liveClass) {
      setIsVisible(true)
      setIsLive(true)
    } else {
      setIsLive(false)
    }
  }, [liveClass])

  useEffect(() => {
    if (studentJoined) {
      setIsVisible(false)
    }
  }, [studentJoined])

  if (isLoading || error || !liveClass) {
    return <></>
  }

  return (
    <SlideFade
      initialScale={0.9}
      in={liveClass && isVisible}
      offsetY="-24px"
      unmountOnExit
    >
      <Alert status="info" justifyContent="center" textColor="blue.700" pr={12}>
        <AlertIcon />
        <CloseButton
          position="absolute"
          right="8px"
          top="8px"
          onClick={hideAlert}
        />
        <AlertDescription>
          {liveClass?.tutor} is now giving a live{' '}
          <Box as="span" fontWeight="bold">
            <Tooltip label={liveClass.description}>class </Tooltip>
          </Box>
        </AlertDescription>
        <Button
          isLoading={isConnecting}
          onClick={async () => {
            await joinLiveClass({
              classId: liveClass.id,
              meetingId: liveClass.meeting_id,
            })
            onJoined()
          }}
          colorScheme="green"
          size="sm"
          ml={2}
        >
          Join
        </Button>
      </Alert>
    </SlideFade>
  )
}

export default LiveClassAlert
