import { Box } from '@chakra-ui/react'
import AdminNav from './AdminNav'

import { useStreaming } from 'store/useStreaminig'

function AdminLayout(props) {
  const isStreaming = useStreaming((state) => state.isStreaming)

  return (
    <Box
      minH={'calc(100vh - 64px)'}
      bg={isStreaming ? 'transparent' : 'gray.200'}
    >
      <AdminNav />
      <div>{props.children}</div>
    </Box>
  )
}

export default AdminLayout
