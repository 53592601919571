import { useLocation, Link } from 'react-router-dom'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  SlideFade,
} from '@chakra-ui/react'

import { useGetGuardianPanicReports } from 'hooks/panic'
import useAuthStore from 'store/useAuthStore'

const PanicAlert = () => {
  const location = useLocation()
  const user = useAuthStore((state) => state.user)
  const { data: panicReports } = useGetGuardianPanicReports(user)

  const isOpen = () =>
    panicReports?.length > 0 && location.pathname !== '/panic-report'

  return (
    <SlideFade initialScale={0.9} in={isOpen()} offsetY="-24px" unmountOnExit>
      <Alert status="warning" justifyContent="center" textColor="orange.700">
        <AlertIcon />
        <AlertTitle>There is an open panic report for this account</AlertTitle>
        <Button as={Link} to="/panic-report" colorScheme="orange" size="sm">
          View
        </Button>
      </Alert>
    </SlideFade>
  )
}

export default PanicAlert
