import { useEffect, useState } from 'react'
import { Box, Text, Flex } from '@chakra-ui/react'
import { AiTwotoneNotification } from 'react-icons/ai'

const NextRoundCountdown = ({ countdown, onClose }) => {
  const [seconds, setSeconds] = useState(countdown)

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => prev - 1)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [countdown])

  useEffect(() => {
    if (seconds === 0) {
      onClose()
    }
  }, [seconds])

  return (
    <Box bg="green.100" px={8} py={4} rounded="md" shadow="lg">
      <Flex
        align="center"
        fontSize="3xl"
        fontWeight="extrabold"
        color="green.600"
      >
        <AiTwotoneNotification />
        <Text ml={3}>Round over</Text>
      </Flex>
      <Text fontSize="xl" color="green.600">
        Next Round starts in {seconds}
      </Text>
    </Box>
  )
}

export default NextRoundCountdown
