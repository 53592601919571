import jwtDecode from 'jwt-decode'
import axios from 'lib/axios'
import useSettingsStore from 'store/useSettings'
import useAuthStore from 'store/useAuthStore'
import parseAvatar from 'utils/parseUserAvatar'

export const handleAuthentication = (accessToken) => {
  if (isValidToken(accessToken)) {
    setSession(accessToken)
  }
}

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false
  }

  const decoded = jwtDecode(accessToken)
  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

const setSession = (accessToken) => {
  if (accessToken) {
    axios.defaults.headers.common.token = accessToken
    setExam()
  }
}

export const signin = async (name, password) => {
  const response = await axios.post('/api/chess/auth/signin', {
    username: name,
    password,
  })

  const { token, user } = response.data
  user.avatar = parseAvatar(user.avatar)

  setSession(token)

  if (user.role === 'admin') {
    user.isAdmin = true
    user.status = 'admin'
  } else if (user.isChild) {
    user.status = 'child'
  } else {
    user.status = 'guardian'
  }

  const userSettings = await getUsersSettings(user.userId)

  return {
    user,
    userSettings,
    accessToken: token,
  }
}

export const signup = async ({
  username,
  firstName,
  surname,
  email,
  password,
  avatar,
  isChild,
  promoCode,
}) => {
  const response = await axios.post('/api/chess/auth/signup', {
    username,
    firstName,
    surname,
    email,
    password,
    avatar,
    isChild,
    code: promoCode,
  })

  const { user, token } = response.data

  setSession(token)

  if (user.role === 'admin') {
    user.isAdmin = true
    user.status = 'admin'
  } else if (user.isChild) {
    user.status = 'child'
  } else {
    user.status = 'guardian'
  }

  return {
    user,
    accessToken: token,
  }
}

export const saveOrderDetails = async (order) => {
  const response = await axios.post('/api/premium/payment', order)
  return response.data
}

export const isPremiumUser = async () => {
  const response = await axios.get('/api/premium/verify')
  return response.data.isPremium
}

export const applyPromoCode = (code) => {
  return axios.post('/api/premium/code', { code })
}

export const sendSignupQuizScore = (score) => {
  return axios.post('/api/profile/signin/quiz', {
    score,
  })
}

export const createChildAccount = async ({
  username,
  firstName,
  surname,
  password,
  avatar,
}) => {
  const response = await axios.post('/api/chess/auth/signup/guardian-child', {
    username,
    firstName,
    surname,
    password,
    avatar,
  })

  return response.data
}

export const updateChildsPassword = async (childId, newPassword) => {
  const response = await axios.post('/api/guardian/child/password/reset', {
    childId,
    newPassword,
  })
  return response.data
}

export const updateAvatar = async (avatarUrl) => {
  return axios.post('/api/profile/avatar/save', {
    avatarPath: avatarUrl,
  })
}

export const changePassword = async (currentPassword, newPassword) => {
  return axios.post('/api/password/chess/reset', {
    currentPassword,
    newPassword,
  })
}

export const requestNewUsername = (username) => {
  return axios.post('/api/request/username', {
    requestedUsername: username,
  })
}

export const requestUsernameForChild = (childId, username) => {
  return axios.post('/api/request/username-by-guardian', {
    userId: childId,
    requestedUsername: username,
  })
}

export const getRequestUsernames = async () => {
  const response = await axios.get('/api/request/all/usernames')

  return response.data
}

export const acceptRequestedUsername = async (userId, pendingUsername) => {
  await axios.post('/api/request/confirm/username', {
    userId,
    pendingUsername,
  })
}

export const rejectRequestedUsername = (childId) => {
  return axios.post('/api/reject/username', {
    childId,
  })
}

export const getGuardiansChildren = async () => {
  const user = useAuthStore.getState().user

  const response = await axios.post('/api/guardian/get', {
    guardianId: user.userId,
  })
  return response.data
}

export const sendPasswordResetEmail = async (email) => {
  const response = await axios.post('/password/code', {
    email,
  })
  return response.data
}

export const updatePassword = async (password, email, code) => {
  const response = await axios.post('/password/reset', {
    password,
    email,
    code,
  })
  return response.data
}

export const getUserProfile = async () => {
  const response = await axios.get('/api/account')
  return response.data
}

export const updateUserProfile = async (updates) => {
  await axios.put('/api/profile', updates)
}

export const uploadAvatar = async (imageData) => {
  return axios.post('/api/profile/image/profile', imageData)
}

export const getUsersSettings = async () => {
  const response = await axios.get('/api/chess/preferences')
  const userSettings = response.data

  if (!userSettings) return

  userSettings.sounds = !!userSettings.sounds
  userSettings.animations = !!userSettings.animations
  userSettings.evalBar = !!userSettings.evalBar
  userSettings.incognito = !!userSettings.incognito

  return userSettings
}

export const updateUserSettings = (updates) => {
  const currentSettings = useSettingsStore.getState().current()
  return axios.post('/api/chess/preferences', {
    ...currentSettings,
    ...updates,
    incognito: currentSettings.isIncognito,
  })
}

export const getUsersVideoPermission = async () => {
  const response = await axios.get('/api/chess/admin/permission')
  return response.data
}

export const postUserVideoPermission = async (userId) => {
  const response = await axios.post('/api/chess/admin/permission', {
    userId: userId,
  })

  return response.data
}

export const deleteUserVideoPermission = async (userId) => {
  const response = await axios.delete(`/api/chess/admin/permission/${userId}`)

  return response.data
}

export const getRandomUsernames = async () => {
  const response = await axios.get('/api/chess/auth/random-usernames')
  return response.data
}

const setExam = () =>
  axios.put('/api/profile/exam', {
    examId: 126,
  })
