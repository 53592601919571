import { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  SimpleGrid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  useToast,
} from '@chakra-ui/react'
import ms from 'ms'
import { gameIcons } from 'constants/gameIcons'
import ChallengeColorIcon from './ChallengeColorIcon'

import useChallengeOpponent from 'store/useChallengeOpponent'
import { useSocket } from 'context/socket-context'

const gameTypes = {
  dynamite: {
    min: 10,
    max: 120,
    default: 30,
    color: 'red',
  },
  blitz: {
    min: 3,
    max: 9,
    default: 4,
    color: 'orange',
  },
  rapid: {
    min: 10,
    max: 30,
    default: 12,
    color: 'green',
  },
}

export default function ChallengeModal() {
  const socket = useSocket()
  const toast = useToast()
  const { isOpen, onClose, opponent, isRematch } = useChallengeOpponent()
  const [type, setType] = useState('dynamite')
  const [isLoading, setIsLoading] = useState(false)
  const [clock, setClock] = useState(gameTypes.dynamite.default)
  const [color, setColor] = useState('random')

  const changeTypeHandler = (type) => {
    setType(type)
    setClock(gameTypes[type].default)
  }

  const colorChangeHandler = () => {
    setColor((prevState) => {
      if (prevState === 'random') {
        return 'white'
      } else if (prevState === 'white') {
        return 'black'
      } else {
        return 'random'
      }
    })
  }

  const emitChallenge = () => {
    setIsLoading(true)

    const opponentColor =
      color === 'white' ? 'black' : color === 'black' ? 'white' : 'random'
    const clockMs = type === 'dynamite' ? ms(`${clock}s`) : ms(`${clock}m`)

    const challenge = {
      to: opponent.id || opponent.userId,
      type: type,
      clock: clockMs,
      color: opponentColor,
      isRematch,
    }

    socket.timeout(5000).emit('challenge', challenge, (err, res) => {
      setIsLoading(false)

      if (err) {
        toast({
          status: 'error',
          title: 'Unable to contact server',
          description: 'Please try again',
        })
      } else {
        toast({
          status: res.status,
          title: res.message,
          duration: 2000,
          position: 'bottom-right',
        })
      }

      onClose()
    })
  }

  const min = gameTypes[type].min
  const max = gameTypes[type].max
  const typeColor = gameTypes[type].color

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg="blue.100" color="blue.500" roundedTop="md">
          {isRematch ? 'Rematch' : 'Challenge'} {opponent?.username}
        </ModalHeader>
        <ModalCloseButton color="blue.500" />
        <ModalBody maxH="md" overflow="auto" textAlign="center">
          <Text>Select game type</Text>
          <SimpleGrid columns={3} spacing={4} textAlign="center" my={4} pt={2}>
            <Box
              as="button"
              position="relative"
              borderWidth={2}
              borderColor={
                type === 'dynamite' ? `${typeColor}.300` : 'gray.200'
              }
              color={type === 'dynamite' ? `${typeColor}.500` : 'gray.500'}
              fontWeight={type === 'dynamite' ? 'semibold' : 'normal'}
              rounded="md"
              py={8}
              onClick={() => changeTypeHandler('dynamite')}
            >
              <Flex
                position="absolute"
                top={-4}
                left={0}
                right={0}
                justify="center"
              >
                <Icon
                  rounded="full"
                  p={1}
                  bg={type === 'dynamite' ? `${typeColor}.100` : 'gray.200'}
                  boxSize={9}
                  as={gameIcons.dynamite}
                />
              </Flex>
              Dynamite
            </Box>
            <Box
              position="relative"
              as="button"
              borderWidth={2}
              borderColor={type === 'blitz' ? `${typeColor}.300` : 'gray.200'}
              color={type === 'blitz' ? `${typeColor}.500` : 'gray.500'}
              fontWeight={type === 'blitz' ? 'semibold' : 'normal'}
              rounded="md"
              py={8}
              onClick={() => changeTypeHandler('blitz')}
            >
              <Flex
                position="absolute"
                top={-4}
                left={0}
                right={0}
                justify="center"
              >
                <Icon
                  rounded="full"
                  p={1}
                  bg={type === 'blitz' ? `${typeColor}.100` : 'gray.200'}
                  boxSize={9}
                  as={gameIcons.blitz}
                />
              </Flex>
              Blitz
            </Box>
            <Box
              position="relative"
              as="button"
              borderWidth={2}
              borderColor={type === 'rapid' ? `${typeColor}.300` : 'gray.200'}
              color={type === 'rapid' ? `${typeColor}.500` : 'gray.500'}
              fontWeight={type === 'rapid' ? 'semibold' : 'normal'}
              rounded="md"
              py={8}
              onClick={() => changeTypeHandler('rapid')}
            >
              <Flex
                position="absolute"
                top={-4}
                left={0}
                right={0}
                justify="center"
              >
                <Icon
                  rounded="full"
                  p={1}
                  bg={type === 'rapid' ? `${typeColor}.100` : 'gray.200'}
                  boxSize={9}
                  as={gameIcons.rapid}
                />
              </Flex>
              Rapid
            </Box>
          </SimpleGrid>

          {type === 'dynamite' ? (
            <Box>Seconds per move: {clock}</Box>
          ) : (
            <Box>Minutes per side: {clock}</Box>
          )}

          <Slider
            mt={3}
            value={clock}
            min={min}
            max={max}
            step={1}
            onChange={(val) => setClock(val)}
          >
            <SliderTrack bg={'gray.200'}>
              <Box position="relative" right={10} />
              <SliderFilledTrack bg={`blue.300`} />
            </SliderTrack>
            <SliderThumb
              _focus={{ outline: 'none', shadow: 'none' }}
              bg={`blue.300`}
              boxSize={5}
            />
          </Slider>

          <Box mt={2}>
            <Text fontSize="xs" color="gray.500">
              You play as
            </Text>
            <Text fontWeight="semibold" color="gray.700">
              {color}
            </Text>
            <ChallengeColorIcon
              as="button"
              mx="auto"
              onClick={colorChangeHandler}
              color={color}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button disabled={isLoading} onClick={onClose}>
            cancel
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            isLoading={isLoading}
            onClick={emitChallenge}
          >
            send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
