import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react'

const modesDesc = {
  quiz: 'In quiz mode you can use the settings to change the difficulty of the questions. No attempts here will affect your ranking.',
  rush: 'Answer as many questions as you can before your time runs out. If you do well here it will improve your ranking.',
  cards: 'Try and figure out the best move before flipping the cards. ',
}

function RankInfoPopover({ mode, children }) {
  return (
    <Popover placement="bottom" closeOnBlur={false}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        color="white"
        bg="blue.800"
        borderColor="blue.800"
        width={{ base: 280 }}
        ml={1}
      >
        <PopoverHeader
          pt={4}
          fontWeight="bold"
          border="0"
          textAlign="center"
          textTransform="capitalize"
        >
          {mode} Mode
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>{modesDesc[mode]}</PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        ></PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

export default RankInfoPopover
