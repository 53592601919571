import { useQuery, useQueryCache } from 'react-query'

import axios from 'lib/axios'

const getConversations = async () => {
  const response = await axios.get('/api/chess/messages')
  return response.data
}

export const useConversations = () => {
  return useQuery('conversations', getConversations, {
    initialData: [],
    forceFetchOnMount: true,
  })
}

const getConversation = async (userId) => {
  const response = await axios.get(`/api/chess/messages/${userId}`)
  return response.data
}

export const useConversation = (userId) => {
  const queryCache = useQueryCache()

  return useQuery(['conversation', userId], () => getConversation(userId), {
    onSuccess: () => {
      queryCache.invalidateQueries('conversations')
      queryCache.invalidateQueries('unread-msg-count')
    },
  })
}

const getUnreadCount = async () => {
  const response = await axios.get('/api/chess/messages/unread-count')
  return response.data
}

export const useUnreadCount = () => {
  return useQuery('unread-msg-count', getUnreadCount)
}
