// used to show message hint bubble
import create from 'zustand'

const useShowHint = create((set, get) => ({
  showHint: false,
  hintText: '',
  setHintText: (hint) => {
    set({ hintText: hint })
  },
  hideHint: () => {
    set({ showHint: false })
  },
  toggleHint: () => {
    set({ showHint: !get().showHint })
  },
}))

export default useShowHint
