import { useQuery, useMutation } from 'react-query'

import {
  getPanicReports,
  getGuardiansPanicReports,
  updatePanicReport,
} from 'services/panic'

export const useGetPanicReports = (filter = {}) => {
  return useQuery(['panic-reports', filter], () => getPanicReports(filter))
}

export const useGetGuardianPanicReports = (user) => {
  return useQuery(
    'panic-reports',
    () => getGuardiansPanicReports(user?.userId),
    {
      enabled: user && !user.isChild,
    },
  )
}

export const useMutatePanicReport = () => {
  return useMutation(updatePanicReport)
}
