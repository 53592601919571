// Parses all variations of profile images returned from different routes
const parseAvatar = (img) => {
  if (typeof img !== 'string') return

  const avatarCdn = 'http://diatldtcixe86.cloudfront.net/profile/'

  if (img.includes('https://avatars.dicebear')) {
    return img.slice(img.indexOf('https://avatars.dicebear'))
  } else if (img.includes('https://api.dicebear')) {
    return img.slice(img.indexOf('https://api.dicebear'))
  } else if (img.includes('https://res.cloudinary')) {
    return img
      .slice(img.indexOf('https://res.cloudinary'))
      .split('/upload')
      .join('/upload/w_180')
  } else if (img.includes(avatarCdn)) {
    return img
  } else {
    return `${avatarCdn}${img}`
  }
}

export default parseAvatar
