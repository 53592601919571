import create from 'zustand'

const useChallengeOpponent = create((set, get) => ({
  isOpen: false,
  isRematch: false,
  opponent: null,
  onClose: () => {
    set({ isOpen: false })
  },
  challenge: (player, isRematch = false) => {
    set({ opponent: player, isOpen: true, isRematch })
  },
  challengeDeclined: () => {
    set({ isOpen: false })
  },
  toggle: () => {
    set({ isOpen: !get().isOpen })
  },
}))

export default useChallengeOpponent
