import { Avatar, Box, Icon, Text, Flex } from '@chakra-ui/react'
import { GiCheckMark } from 'react-icons/gi'
import { AiOutlineClose } from 'react-icons/ai'

const ChallengeResponse = ({ challenge, status, onClose }) => {
  return (
    <Box
      mt={4}
      rounded="md"
      bg="gray.100"
      py={2}
      px={{ base: 1, md: 4 }}
      position="relative"
      boxShadow="0px 10px 40px -5px rgba(0, 0, 0, .9)"
      borderWidth={2}
      borderColor={status === 'accepted' ? 'green.500' : 'red.300'}
    >
      <Box position="absolute" top={-3} right={2}>
        <Icon
          color="white"
          bg={status === 'accepted' ? 'green.500' : 'red.400'}
          rounded="full"
          as={status === 'accepted' ? GiCheckMark : AiOutlineClose}
          mx={1}
          w={5}
          h={5}
          p={1}
          _hover={{ cursor: "pointer" }}
          onClick={onClose}
        />
      </Box>
      <Flex>
        <Flex direction="column" align="center">
          <Avatar w={16} h={16} src={challenge?.to?.avatar} />
          <Box
            fontWeight="semibold"
            fontSize="sm"
            rounded="md"
            bg="green.400"
            color="green.100"
            px={2}
            py={0.5}
            mt={-2}
            zIndex={3}
            lineHeight={1}
          >
            {challenge?.to?.rating}
          </Box>
        </Flex>
        <Box px={2} maxW={{ md: '320px' }} pt={2}>
          <Text fontSize="xl" textColor="gray.600" fontWeight="extrabold">
            Challenge {status}!
          </Text>

          <Text textColor="gray.500" lineHeight={1.1}>
            {challenge?.to?.username} {status} your challenge.
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}

export default ChallengeResponse
