import { useHistory } from 'react-router-dom'
import { Avatar, Flex, useDisclosure } from '@chakra-ui/react'

import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

import useAuthStore from 'store/useAuthStore'
import SettingsModal from 'components/SettingsModal'

const menuItemStyle = {
  width: '100%',
  active: { background: '#eee', color: '#000' },
}

export default function NavMenu({ ...styles }) {
  const logout = useAuthStore((state) => state.logout)
  const user = useAuthStore((state) => state.user)
  const history = useHistory()
  const { isOpen, onClose } = useDisclosure()

  function handleLogout() {
    logout()
    history.push('/')
  }

  function handleSettingsClick() {
    history.push('/settings')
  }

  function gotoChildAccountsPage() {
    history.push('/my-kids')
  }

  function upgradeAccount() {
    history.push('/upgrade-account')
  }

  return (
    <>
      <Flex {...styles}>
        <Menu
          menuButton={
            <MenuButton>
              <Avatar
                size={'md'}
                src={user?.avatar}
                borderWidth={2}
                borderColor="green.300"
              />
            </MenuButton>
          }
          direction="bottom"
          transition
        >
          {!user?.isChild && (
            <MenuItem styles={menuItemStyle} onClick={gotoChildAccountsPage}>
              My Kids
            </MenuItem>
          )}

          <MenuItem styles={menuItemStyle} onClick={handleSettingsClick}>
            My Account
          </MenuItem>

          {/* {!user?.isPremium && (
            <MenuItem styles={menuItemStyle} onClick={upgradeAccount}>
              Upgrade Account
            </MenuItem>
          )} */}

          <MenuDivider />
          <MenuItem onClick={handleLogout} styles={menuItemStyle}>
            Log out
          </MenuItem>
        </Menu>
      </Flex>
      <SettingsModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}
