import { useRef } from 'react'
import { Box, Text, SlideFade, useOutsideClick } from '@chakra-ui/react'

import useShowHint from 'store/useShowHint'
import AudioButton from './AudioButton'

export default function HintMessageBubble() {
  const ref = useRef()

  const hintText = useShowHint((state) => state.hintText)
  const showHint = useShowHint((state) => state.showHint)
  const hideHint = useShowHint((state) => state.hideHint)

  useOutsideClick({
    ref: ref,
    handler: () => {
      if (showHint) {
        hideHint()
      }
    },
  })

  return (
    <SlideFade in={showHint} offsetY="-20px" unmountOnExit>
      <Box position="relative" ref={ref} zIndex={9}>
        <Box
          position="absolute"
          left={10}
          h={2}
          w={2}
          top={-8}
          borderLeft="40px solid transparent"
          borderRight="30px solid white"
          borderTop="20px solid transparent"
          borderBottom="20px solid transparent"
          transform="rotate(52deg)"
        ></Box>
        <Box
          position="absolute"
          w={{ base: 64, md: 72 }}
          bg="white"
          px={4}
          py={2}
          left={{ md: 20 }}
          roundedBottom="lg"
          roundedTopRight="lg"
          roundedTopLeft={{ base: 'lg', md: 'none' }}
        >
          <Text fontSize="xl">
            {hintText} <AudioButton text={hintText} />
          </Text>
        </Box>
      </Box>
    </SlideFade>
  )
}
