import {
  Box,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Switch,
  Select,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { FiSettings } from 'react-icons/fi'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import useSettings, { availableColors } from 'store/useSettings'
import { updateUserSettings } from 'services/auth'
import pieces from 'constants/pieces'

function SettingsModal({ isOpen, onClose }) {
  const settings = useSettings()

  async function saveUserSettings() {
    try {
      await updateUserSettings()
    } catch (err) {
      // alert('Error saving settings!')
    }
  }

  const handleClose = () => {
    saveUserSettings()
    onClose()
  }

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={handleClose}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent
        bg="blue.200"
        color="blue.800"
        maxW={{ base: '96%', sm: 'md' }}
        pb={4}
      >
        <ModalHeader
          fontSize={{ base: 'xl', md: '3xl' }}
          roundedTop="md"
          fontWeight="bold"
          display="flex"
          alignItems="center"
          bg="blue.400"
          color="white"
        >
          <FiSettings size={24} style={{ paddingTop: '4px' }} />{' '}
          <Text ml={1}>Settings</Text>
        </ModalHeader>
        <ModalCloseButton mt={{ base: 2, md: 4 }} />
        <ModalBody py={4}>
          <Stack spacing={4}>
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel
                htmlFor="pieces"
                mb="0"
                fontSize={{ base: 'noraml', md: 'lg' }}
                fontWeight="bold"
              >
                Pieces:
              </FormLabel>
              <Select
                id="pieces"
                w={40}
                value={settings.pieces}
                onChange={(e) => settings.updatePieces(e.target.value)}
                fontSize={{ base: 'noraml', md: 'lg' }}
                fontWeight="bold"
              >
                {pieces.map((piece) => (
                  <option
                    key={piece}
                    value={piece}
                    style={{ background: '#e8e8e8' }}
                  >
                    {piece}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel
                htmlFor="boardType"
                mb="0"
                fontSize={{ base: 'noraml', md: 'lg' }}
                fontWeight="bold"
              >
                Board Type:
              </FormLabel>
              <Select
                id="boardType"
                w={40}
                value={settings.boardType}
                onChange={(e) => settings.updateBoardType(e.target.value)}
                fontSize={{ base: 'noraml', md: 'lg' }}
                fontWeight="bold"
              >
                <option value="2D" style={{ background: '#e8e8e8' }}>
                  2D
                </option>
                <option value="3D" style={{ background: '#e8e8e8' }}>
                  3D
                </option>
              </Select>
            </FormControl>

            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel
                htmlFor="board"
                mb="0"
                fontSize={{ base: 'noraml', md: 'lg' }}
                fontWeight="bold"
              >
                Board Color:
              </FormLabel>
              <Select
                id="board"
                w={40}
                value={settings.boardColor}
                onChange={(e) => settings.updateBoardcolor(e.target.value)}
                fontSize={{ base: 'noraml', md: 'lg' }}
                fontWeight="bold"
              >
                {availableColors.map((color) => (
                  <option
                    key={color}
                    value={color}
                    style={{ background: '#e8e8e8' }}
                  >
                    {color}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel
                htmlFor="play-sounds"
                mb="0"
                fontSize={{ base: 'noraml', md: 'lg' }}
                fontWeight="bold"
              >
                Play sounds:
              </FormLabel>
              <Switch
                id="play-sounds"
                isChecked={settings.playSounds}
                onChange={settings.toggleSounds}
                colorScheme="green"
                size="lg"
              />
            </FormControl>
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel
                htmlFor="play-animations"
                mb={0}
                fontSize={{ base: 'noraml', md: 'lg' }}
                fontWeight="bold"
                display="flex"
              >
                Play animations:
                <Tooltip label="Only available for the Animated pieces.">
                  <Box>
                    <AiOutlineInfoCircle size={16} />
                  </Box>
                </Tooltip>
              </FormLabel>
              <Switch
                id="play-animations"
                isChecked={settings.playAnimations}
                onChange={settings.toggleAnimations}
                colorScheme="green"
                size="lg"
              />
            </FormControl>
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel
                htmlFor="show-evaluation-bar"
                mb={0}
                fontSize={{ base: 'noraml', md: 'lg' }}
                fontWeight="bold"
              >
                Evaluation bar:
              </FormLabel>
              <Switch
                id="show-evaluation-bar"
                isChecked={settings.showEvaluationBar}
                onChange={settings.toggleEvaluationBar}
                colorScheme="green"
                size="lg"
              />
            </FormControl>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SettingsModal
