import { useRef } from 'react'
import {
  Button,
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { IoSettingsSharp } from 'react-icons/io5'
import { FiSun, FiMoon } from 'react-icons/fi'
import { BsEmojiSmile, BsEmojiSunglasses } from 'react-icons/bs'

import useSettingsStore from 'store/useSettings'
import useAuthStore from 'store/useAuthStore'
import { updateUserSettings } from 'services/auth'
import { useSocket } from 'context/socket-context'

function SettingsDrawerButton() {
  const socket = useSocket()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  const user = useAuthStore((state) => state.user)
  const currentVariant = useSettingsStore((state) => state.bgVariant)
  const updateBgVariant = useSettingsStore((state) => state.updateBgVariant)
  const isIncognito = useSettingsStore((state) => state.isIncognito)
  const updateActivityStatus = useSettingsStore(
    (state) => state.updateActivityStatus,
  )

  const updateColorMode = async (mode) => {
    updateBgVariant(mode)
    try {
      await updateUserSettings({ mode })
    } catch (err) {
      alert('error saving your settings')
    }
  }

  const changeActivityStatus = async (isIncognito) => {
    if (socket.connected) {
      updateActivityStatus(isIncognito)
      socket.emit('updateIncognitoMode', { isIncognito })

      try {
        await updateUserSettings({ incognito: isIncognito })
      } catch (error) {
        alert('Error persisting activity status change')
      }
    } else {
      alert('You are disconnected!')
    }
  }

  return (
    <>
      <Box
        ref={btnRef}
        as="button"
        ml={2}
        color="white"
        p={2}
        rounded="full"
        bg="blackAlpha.100"
        _hover={{ bg: 'blackAlpha.300' }}
        _focus={{ bg: 'blackAlpha.300' }}
        onClick={onOpen}
      >
        <IoSettingsSharp size={24} />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          roundedLeft="2xl"
          bgGradient="linear(to-b,  blue.100 380px, whiteAlpha.600)"
          bg="transparent"
          overflow="hidden"
        >
          <DrawerCloseButton mt={4} color="blue.500" />
          <DrawerHeader
            borderBottomWidth={1}
            py={6}
            color="blue.400"
            bg="transparent"
          >
            Settings
          </DrawerHeader>

          <DrawerBody pt={6}>
            <Box>
              <Text color="blue.400" fontWeight="semibold" mb={1}>
                Mode
              </Text>
              <Flex>
                <Button
                  leftIcon={<FiSun />}
                  variant="outline"
                  borderColor={
                    currentVariant === 'day' ? 'green.300' : 'blue.100'
                  }
                  color={currentVariant === 'day' ? 'green.500' : 'gray.600'}
                  bg={currentVariant === 'day' ? 'green.100' : 'blue.50'}
                  w="full"
                  roundedRight={0}
                  onClick={() => updateColorMode('day')}
                  _focus={{ outline: 'none' }}
                  _hover={{
                    bg: 'green.50',
                  }}
                >
                  Light
                </Button>
                <Button
                  leftIcon={<FiMoon />}
                  variant="outline"
                  borderColor={
                    currentVariant === 'night' ? 'green.300' : 'blue.100'
                  }
                  color={currentVariant === 'night' ? 'green.500' : 'gray.600'}
                  bg={currentVariant === 'night' ? 'green.100' : 'blue.50'}
                  w="full"
                  roundedLeft={0}
                  onClick={() => updateColorMode('night')}
                  _focus={{ outline: 'none' }}
                >
                  Dark
                </Button>
              </Flex>
            </Box>

            <Box mt={6}>
              <Text color="blue.400" fontWeight="semibold" mb={1}>
                Activity Status
              </Text>
              <Flex>
                <Button
                  leftIcon={<BsEmojiSmile />}
                  variant="outline"
                  borderColor={!isIncognito ? 'green.300' : 'blue.100'}
                  color={!isIncognito ? 'green.500' : 'gray.600'}
                  bg={!isIncognito ? 'green.100' : 'blue.50'}
                  onClick={() => changeActivityStatus(false)}
                  w="full"
                  roundedRight={0}
                  _focus={{ outline: 'none' }}
                >
                  Online
                </Button>
                <Button
                  leftIcon={<BsEmojiSunglasses />}
                  borderColor={isIncognito ? 'green.300' : 'blue.100'}
                  color={isIncognito ? 'green.500' : 'gray.600'}
                  bg={isIncognito ? 'green.100' : 'blue.50'}
                  variant="outline"
                  w="full"
                  roundedLeft={0}
                  onClick={() => changeActivityStatus(true)}
                  _focus={{ outline: 'none' }}
                >
                  Offline
                </Button>
              </Flex>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default SettingsDrawerButton
