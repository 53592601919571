import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Button } from '@chakra-ui/react'
import { BsVolumeUpFill } from 'react-icons/bs'

import audioService from 'services/audio'

const AudioButton = forwardRef(({ text, onPlay, onPause, ...rest }, ref) => {
  const audioElement = useRef(null)
  const audioSource = useRef(null)
  const audioUrlRef = useRef(null)

  const [isLoadingAudio, setIsLoadingAudio] = useState(false)
  const [isPlayingAudio, setIsPlayingAudio] = useState(false)

  useImperativeHandle(ref, () => {
    return { audio: audioElement.current }
  })

  function disposeAudio() {
    if (audioUrlRef.current) {
      audioService.dispose(audioUrlRef.current)
      audioUrlRef.current = null
    }

    audioElement.current?.pause()
  }

  useEffect(() => {
    audioElement.current = document.createElement('audio')
    audioSource.current = document.createElement('source')
    audioSource.current.type = 'audio/mp3'
    audioElement.current.appendChild(audioSource.current)

    audioElement.current.addEventListener('ended', () => {
      setIsPlayingAudio(false)
    })

    return disposeAudio
  }, [])

  const clickHandler = async () => {
    audioElement.current?.pause()

    if (isPlayingAudio) {
      if (onPause) {
        onPause()
      }
      return setIsPlayingAudio(false)
    }

    setIsPlayingAudio(true)

    if (!audioUrlRef.current) {
      setIsLoadingAudio(true)

      try {
        audioUrlRef.current = await audioService.textToAudio(text)
        setIsLoadingAudio(false)
      } catch (err) {
        alert('Error converting  text to audio!')
        return setIsLoadingAudio(false)
      }
    }

    audioSource.current.src = audioUrlRef.current
    audioElement.current.load()
    audioElement.current.play()

    if (onPlay) {
      onPlay(audioElement.current)
    }
  }

  return (
    <Button
      onClick={clickHandler}
      isLoading={isLoadingAudio}
      aria-label="Play Audio"
      bg="whiteAlpha.500"
      color={isPlayingAudio ? 'green.500' : 'blackAlpha.800'}
      {...rest}
    >
      <BsVolumeUpFill />
    </Button>
  )
})

export default AudioButton
