import { Box, Flex } from '@chakra-ui/react'
import { SpinningKnightLoader } from 'components/LoadingScreen'

import SignupQuizLayout from 'layouts/SignupQuizLayout'

const Page = () => {
  return (
    <SignupQuizLayout>
      <Flex justify="center" align="center" h="70vh" direction="column">
        <SpinningKnightLoader />
        <Box fontWeight="bold" color="blackAlpha.500">
          Connecting...
        </Box>
      </Flex>
    </SignupQuizLayout>
  )
}

export default Page
