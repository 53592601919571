import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/glbs/HouseWithPenguin.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    if (actions) {
      actions['House.159Action.004']
        .play()
        .setLoop(2200).clampWhenFinished = true

      actions['ArmatureAction'].play().setLoop(2200).clampWhenFinished = true
      actions['KeyAction'].play().setLoop(2200).clampWhenFinished = true
    }
  }, [])

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      position={[0, -7.7, 9]}
      rotation={[0, -1.6, 0]}
    >
      <group name="Scene">
        <group
          name="Empty"
          position={[-4.44, 2.6, 0.05]}
          rotation={[1.57, 0.03, -1.54]}
        />
        <group name="Armature" position={[-0.01, 0.49, 0.04]} scale={2.06}>
          <primitive object={nodes.Bone} />
          <group name="Sphere002">
            <skinnedMesh
              name="Sphere002_1"
              geometry={nodes.Sphere002_1.geometry}
              material={materials.Material}
              skeleton={nodes.Sphere002_1.skeleton}
            />
            <skinnedMesh
              name="Sphere002_2"
              geometry={nodes.Sphere002_2.geometry}
              material={materials['Material.005']}
              skeleton={nodes.Sphere002_2.skeleton}
            />
            <skinnedMesh
              name="Sphere002_3"
              geometry={nodes.Sphere002_3.geometry}
              material={materials['Material.006']}
              skeleton={nodes.Sphere002_3.skeleton}
            />
          </group>
          <skinnedMesh
            name="Sphere006"
            geometry={nodes.Sphere006.geometry}
            material={materials['Material.001']}
            skeleton={nodes.Sphere006.skeleton}
          />
          <skinnedMesh
            name="Sphere007"
            geometry={nodes.Sphere007.geometry}
            material={materials['Material.001']}
            skeleton={nodes.Sphere007.skeleton}
          />
          <skinnedMesh
            name="Sphere008"
            geometry={nodes.Sphere008.geometry}
            material={materials['Material.003']}
            skeleton={nodes.Sphere008.skeleton}
          />
          <skinnedMesh
            name="Sphere009"
            geometry={nodes.Sphere009.geometry}
            material={materials['Material.003']}
            skeleton={nodes.Sphere009.skeleton}
          />
          <group name="Sphere001">
            <skinnedMesh
              name="Sphere001_1"
              geometry={nodes.Sphere001_1.geometry}
              material={materials.Material}
              skeleton={nodes.Sphere001_1.skeleton}
              morphTargetDictionary={nodes.Sphere001_1.morphTargetDictionary}
              morphTargetInfluences={nodes.Sphere001_1.morphTargetInfluences}
            />
            <skinnedMesh
              name="Sphere001_2"
              geometry={nodes.Sphere001_2.geometry}
              material={materials['Material.001']}
              skeleton={nodes.Sphere001_2.skeleton}
              morphTargetDictionary={nodes.Sphere001_2.morphTargetDictionary}
              morphTargetInfluences={nodes.Sphere001_2.morphTargetInfluences}
            />
            <skinnedMesh
              name="Sphere001_3"
              geometry={nodes.Sphere001_3.geometry}
              material={materials['Material.003']}
              skeleton={nodes.Sphere001_3.skeleton}
              morphTargetDictionary={nodes.Sphere001_3.morphTargetDictionary}
              morphTargetInfluences={nodes.Sphere001_3.morphTargetInfluences}
            />
            <skinnedMesh
              name="Sphere001_4"
              geometry={nodes.Sphere001_4.geometry}
              material={materials['Material.004']}
              skeleton={nodes.Sphere001_4.skeleton}
              morphTargetDictionary={nodes.Sphere001_4.morphTargetDictionary}
              morphTargetInfluences={nodes.Sphere001_4.morphTargetInfluences}
            />
          </group>
        </group>
        <group
          name="House133"
          position={[-9.25, 1.25, -0.76]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={1.57}
        >
          <mesh
            name="Plane001"
            geometry={nodes.Plane001.geometry}
            material={materials['Material.008']}
          />
          <mesh
            name="Plane001_1"
            geometry={nodes.Plane001_1.geometry}
            material={materials['Material.009']}
          />
          <mesh
            name="Plane001_2"
            geometry={nodes.Plane001_2.geometry}
            material={materials['Material.011']}
          />
          <mesh
            name="Plane001_3"
            geometry={nodes.Plane001_3.geometry}
            material={materials['Material.012']}
          />
        </group>
        <group
          name="House159"
          position={[-0.87, 2.8, 0.97]}
          rotation={[-Math.PI, 0.06, -Math.PI]}
          scale={1.55}
        >
          <mesh
            name="Plane002"
            geometry={nodes.Plane002.geometry}
            material={materials['Material.012']}
          />
          <mesh
            name="Plane002_1"
            geometry={nodes.Plane002_1.geometry}
            material={materials['Material.013']}
          />
          <mesh
            name="Plane002_2"
            geometry={nodes.Plane002_2.geometry}
            material={materials['Material.016']}
          />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/glbs/HouseWithPenguin.glb')
