import { Box, Button } from '@chakra-ui/react'
import { ImUsers } from 'react-icons/im'

import useOnlinePlayerDrawer from 'store/useOnlinePlayerDrawer'
import useOnlineUsers from 'store/useOnlineUsers'
import useSettingsStore from 'store/useSettings'

function ColorModeToggleButtons(props) {
  const { toggle } = useOnlinePlayerDrawer()
  const count = useOnlineUsers((state) => state.count)
  const isIncognito = useSettingsStore((state) => state.isIncognito)

  return (
    <Box position="relative">
      <Box
        as="button"
        color="white"
        p={2}
        rounded="full"
        bg="blackAlpha.100"
        _hover={{ bg: 'blackAlpha.300' }}
        _focus={{ bg: 'blackAlpha.300' }}
        onClick={toggle}
      >
        <ImUsers size={22} />
      </Box>
      <Box
        position="absolute"
        top={-2}
        right={-2}
        bg={isIncognito ? 'red.500' : 'green.500'}
        rounded="full"
        px={1.5}
        py={1}
        textAlign="center"
        color="white"
        fontWeight="bold"
        fontSize="xs"
        minW="20px"
        lineHeight={1}
      >
        {count}
      </Box>
    </Box>
  )
}

export default ColorModeToggleButtons
