import { Box } from '@chakra-ui/react'

import './styles.css'

const ZoomBox = () => (
  <>
    <Box
      id="zoomSDK"
      w={0}
      h={0}
      bg="red.500"
      position="absolute"
      top={32}
      left={32}
    />
    <Box
      id="zoomParticipantsEl"
      bg="red.500"
      position="absolute"
      top={16}
      right={0}
      w={0}
      h={0}
    />
    <Box
      id="zoomChatEl"
      bg="red.500"
      position="absolute"
      bottom={0}
      right={0}
      w={0}
      h={0}
    />

  </>

)

export default ZoomBox
