import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { addVirtualBackgrounds } from 'utils/zoomClient'

import ZoomMtgEmbedded from '@zoomus/websdk/embedded'

import {
  getJoinSignature,
  trackClassAttendance,
} from 'services/scheduledClasses'
import { randomId } from 'utils/randomId'
import useAuthStore from 'store/useAuthStore'

export const useJoinLiveClass = () => {
  const toast = useToast()
  const history = useHistory()
  const user = useAuthStore((state) => state.user)
  const client = ZoomMtgEmbedded.createClient()

  const [isConnecting, setIsConnecting] = useState(false)
  const [errorCount, setErrorCount] = useState(0)

  const initZoom = async () => {
    const zoomSDKElement = document.getElementById('zoomSDK')
    const chatElement = document.getElementById('zoomChatEl')
    const participantsElement = document.getElementById('zoomParticipantsEl')

    try {
      await client.init({
        zoomAppRoot: zoomSDKElement,
        language: 'en-US',
        customize: {
          video: {
            viewSizes: {
              default: {
                height: 240,
                width: 240,
              },
            },
          },
          toolbar: {
            buttons: [
              {
                text: 'Leave Lesson',
                onClick: async () => {
                  try {
                    await client.leaveMeeting()
                    ZoomMtgEmbedded.destroyClient()
                  } catch (err) {
                    alert('Error leaving lesson')
                  }
                },
              },
            ],
          },
          setting: {
            popper: {
              disableDraggable: true,
            },
          },
          participants: {
            popper: {
              disableDraggable: true,
              anchorElement: participantsElement,
              placement: 'bottom',
            },
          },
          chat: {
            popper: {
              disableDraggable: true,
              anchorElement: chatElement,
              placement: 'top',
            },
          },
          meetingInfo: [
            'topic',
            'host',
            'mn',
            'pwd',
            'telPwd',
            'invite',
            'participant',
            'dc',
            'enctype',
          ],
        },
      })
    } catch (err) {
      if (err.type) {
        toast({
          title: err.type,
          description: err.reason,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else {
        toast({
          title: 'Error initializing zoom',
          description: 'Refresh page before joining live class',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    }
  }

  const joinLiveClass = async ({ classId, meetingId }) => {
    setIsConnecting(true)
    initZoom()

    try {
      const { signature } = await getJoinSignature(meetingId, user?.username)

      await client.join({
        signature,
        sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY,
        meetingNumber: meetingId,
        userName: user?.username,
        userEmail: `${user?.username}.${randomId()}@cwk.com`,
      })

      client.on('connection-change', (payload) => {
        if (payload.state === 'Closed') {
          ZoomMtgEmbedded.destroyClient()
        }

        if (payload.state === 'Connected') {
          setTimeout(() => {
            addVirtualBackgrounds(client)
          }, 5000)
        }
      })

      await trackClassAttendance(classId)

      history.push('/live-class')
    } catch (err) {
      setErrorCount(errorCount + 1)

      if (err.reason === 'Meeting has not started' && errorCount === 0) {
        toast({
          title: 'Joined class',
          description: 'Waiting for mentor',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      } else if (err.type) {
        toast({
          title: err.type,
          description: err.reason,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else {
        toast({
          title: 'Error joining live class',
          description: err?.response?.data
            ? err.response.data.error
            : err.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    }

    setIsConnecting(false)
  }

  return { isConnecting, joinLiveClass }
}
