import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Alert,
  AlertTitle,
  AlertDescription,
  Avatar,
  Button,
  Box,
  Flex,
  Icon,
  Tooltip,
  ScaleFade,
} from '@chakra-ui/react'
import { FaChessPawn, FaChessKing, FaChessRook } from 'react-icons/fa'
import { BsEmojiSunglasses } from 'react-icons/bs'

import { updateUserSettings } from 'services/auth'
import useOnlineUsers from 'store/useOnlineUsers'
import useAuthStore from 'store/useAuthStore'
import useSettingsStore from 'store/useSettings'
import useChallengeOpponent from 'store/useChallengeOpponent'
import { useSocket } from 'context/socket-context'
import { useFriends } from 'hooks/friends'

const icons = {
  admin: <FaChessKing size={16} />,
  child: <FaChessPawn size={16} />,
  guardian: <FaChessRook size={16} />,
}

export default function OnlinePlayersTab({ closeDrawer }) {
  const socket = useSocket()
  const onlineUsers = useOnlineUsers((state) => state.users)

  const [friendIds, setFriendIds] = useState([])
  const { data: friends } = useFriends()

  const user = useAuthStore((state) => state.user)
  const isIncognito = useSettingsStore((state) => state.isIncognito)
  const updateActivityStatus = useSettingsStore(
    (state) => state.updateActivityStatus,
  )

  const updateIncognitoStatus = async (isIncognito) => {
    if (socket.connected) {
      updateActivityStatus(isIncognito)
      socket.emit('updateIncognitoMode', { isIncognito })

      try {
        await updateUserSettings({ incognito: isIncognito })
      } catch (error) {
        alert('Error persisting activity status change')
      }
    } else {
      alert('You are currently disconnected.')
    }
  }

  useEffect(() => {
    if (friends) {
      setFriendIds(friends.map((friend) => friend.id))
    }
  }, [friends])

  return (
    <Box>
      <ScaleFade in={isIncognito} unmountOnExit>
        <IncognitoAlert onClick={() => updateIncognitoStatus(false)} />
      </ScaleFade>
      <ScaleFade in={!isIncognito} unmountOnExit>
        <CurrentUser user={user} onClick={() => updateIncognitoStatus(true)} />
      </ScaleFade>

      {onlineUsers.map((onlineUser) => {
        if (
          onlineUser.isIncognito ||
          onlineUser.id === user?.userId ||
          onlineUser.userId === user?.userId
        ) {
          return null
        }

        return (
          <User
            key={onlineUser.userId}
            user={onlineUser}
            onClick={closeDrawer}
            isFriend={friendIds.includes(onlineUser.userId)}
          />
        )
      })}
    </Box>
  )
}

function IncognitoAlert({ onClick }) {
  return (
    <Alert variant="subtle" py={3} px={8} h="118px">
      <Flex direction="column">
        <Flex mt={4} mb={1} align="center">
          <Icon as={BsEmojiSunglasses} color="blue.600" boxSize="36px" />
          <AlertTitle ml={2} fontSize="lg" textColor="blue.600" flex={1}>
            Invisible mode
          </AlertTitle>
          <Button size="sm" colorScheme="green" onClick={onClick}>
            Appear Online
          </Button>
        </Flex>

        <AlertDescription textColor="blue.500" lineHeight={1.1}>
          You are in incognito mode. Other users don't know you are online.
        </AlertDescription>
      </Flex>
    </Alert>
  )
}

function CurrentUser({ user, onClick }) {
  return (
    <Box
      px={8}
      py={4}
      borderTopWidth={1}
      borderColor="green.200"
      _hover={{ bg: 'green.200' }}
    >
      <Flex justify="space-between" align="center">
        <Box>
          <Flex align="flex-end">
            <Flex direction="column" align="flex-start" justify="center">
              <Box position="relative">
                <Avatar
                  size={'lg'}
                  src={user?.avatar}
                  borderWidth={2}
                  borderColor="green.300"
                />
                <Tooltip label={user?.status}>
                  <Box
                    position="absolute"
                    right={-4}
                    bottom={1}
                    color="blackAlpha.700"
                  >
                    {icons[user?.status]}
                  </Box>
                </Tooltip>
              </Box>

              <Box
                px={2}
                rounded="md"
                color={'green.100'}
                bg={'green.400'}
                fontSize="sm"
                minW="64px"
                textAlign="center"
                fontWeight="bold"
              >
                You
              </Box>
            </Flex>
          </Flex>
        </Box>

        <Button
          size="sm"
          colorScheme="green"
          onClick={onClick}
          leftIcon={<BsEmojiSunglasses />}
        >
          Go Incognito
        </Button>
      </Flex>
    </Box>
  )
}

function User({ user, isFriend, onClick }) {
  const challengePlayer = useChallengeOpponent((state) => state.challenge)

  const challengePlayerHandler = () => {
    onClick()
    // react-remove-scrollbar chakraui dep bug fix
    setTimeout(() => {
      challengePlayer(user)
    }, 150)
  }

  return (
    <Box
      px={8}
      py={4}
      borderTopWidth={1}
      borderColor="green.200"
      _last={{
        borderBottomWidth: 1,
      }}
      _hover={{
        bg: 'green.200',
      }}
      role="group"
    >
      <Flex justify="space-between" align="center">
        <Box>
          <Flex
            align="flex-end"
            as={Link}
            onClick={onClick}
            to={`/user/${user?.userId}/profile`}
          >
            <Flex direction="column" align="flex-start" justify="center">
              <Box position="relative">
                <Avatar
                  size={'lg'}
                  src={user?.avatar}
                  borderWidth={2}
                  borderColor="green.300"
                />
                <Tooltip label={user?.status}>
                  <Box
                    position="absolute"
                    right={-4}
                    bottom={1}
                    color="blackAlpha.700"
                  >
                    {icons[user?.status]}
                  </Box>
                </Tooltip>
              </Box>

              <Box
                px={2}
                rounded="md"
                color={isFriend ? 'orange.50' : 'gray.50'}
                bg={isFriend ? 'orange.400' : 'gray.400'}
                fontSize="sm"
                minW="64px"
                textAlign="center"
                fontWeight="semibold"
              >
                {user.username}
              </Box>
            </Flex>
          </Flex>
        </Box>
        {user?.isPlaying && !user?.isAdmin ? (
          <Box>
            <Button size="sm" colorScheme="blue">
              Playing
            </Button>
          </Box>
        ) : (
          <Box
            display="none"
            _groupHover={{
              display: 'block',
            }}
          >
            <Button
              size="sm"
              colorScheme={isFriend ? 'orange' : 'gray'}
              onClick={challengePlayerHandler}
            >
              Challenge
            </Button>
          </Box>
        )}
      </Flex>
    </Box>
  )
}
