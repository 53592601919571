import React from 'react'
import { Box, Icon, Heading, Text } from '@chakra-ui/react'
import { ImWarning } from 'react-icons/im'
import { trackEventSlack } from 'services/postSlackMessage'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: '' }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, info) {
    console.log('error', error)

    if (navigator.onLine && window.location.pathname.includes('admin')) {
      trackEventSlack(`ErrorBoundary: ${JSON.stringify(error.stack)}`).catch()
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback error={this.state.error.message} />
    }

    return this.props.children
  }
}

export default ErrorBoundary

function ErrorFallback({ error }) {
  return (
    <Box
      textAlign="center"
      bg="red.100"
      maxW="4xl"
      mx="auto"
      my={24}
      px={4}
      py={8}
      rounded="lg"
    >
      <Icon
        as={ImWarning}
        mx="auto"
        display="flex"
        w={12}
        h={12}
        color="red.400"
      />
      <Heading textAlign="center" color="red.500" my={2}>
        Unexpected error occured!
      </Heading>
      <Text fontSize="lg" fontWeight="semibold" textColor="red.400">
        {navigator?.onLine
          ? 'Try refreshing the page.'
          : 'Looks like you are offline. Check your internet connection'}
      </Text>

      <Text fontSize="xs" mt={2}>
        {error}
      </Text>
    </Box>
  )
}
