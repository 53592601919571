import { Box } from '@chakra-ui/react'

import useSettingsStore from 'store/useSettings'
import PlayersDrawer from 'components/PlayersDrawer/PlayersDrawer'
import PanicAlert from 'components/Panic/PanicAlert'
import WebinarAlert from 'components/WebinarAlert/NextWebinarAlert'
import BroadcastGameAlert from 'components/BroadcastGameAlert/BroadcastGameAlert'
import TournamentOverModal from 'components/Tournament/TournamentOverModal'
import NoGameForRoundModal from 'components/Tournament/NoGameForRoundModal'
import Nav from './Nav'

function MainLayout(props) {
  const bgImage = useSettingsStore((state) => state.background)

  return (
    <Box
      minH="100vh"
      bgSize="cover"
      bgAttachment="fixed"
      bgImage={`url("/images/backgrounds/${bgImage}.svg")`}
      position="relative"
    >
      <PanicAlert />
      <WebinarAlert />
      <BroadcastGameAlert />
      <Nav />

      <main>{props.children}</main>

      <PlayersDrawer />
      <TournamentOverModal />
      <NoGameForRoundModal />
    </Box>
  )
}

export default MainLayout
