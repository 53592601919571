import create from 'zustand'
import { persist } from 'zustand/middleware'

import boardSquareColors from 'constants/boardSquareColors'
import boardColors from 'constants/boardColors'
import useAuthStore from 'store/useAuthStore'

const defaultState = {
  playSounds: true,
  playAnimations: false,
  showEvaluationBar: true,
  pieces: 'Classic',
  boardColor: 'Blue',
  boardType: '2D',
  bgVariant: 'night',
  background: 'Wintery-Sunburst-night',
  isIncognito: false,
}

const store = (set, get) => ({
  ...defaultState,
  current: () => {
    return {
      sounds: get().playSounds,
      animations: get().playAnimations,
      evalBar: get().showEvaluationBar,
      pieces: get().pieces,
      board: get().boardColor,
      bg: get().currentBg(),
      mode: get().bgVariant,
      isIncognito: get().isIncognito,
    }
  },
  darkSquare: () => {
    const color = get().boardColor.toLowerCase()
    return boardSquareColors[color]?.darkSquare || boardColors.blue.darkSquare
  },
  lightSquare: () => {
    const color = get().boardColor.toLowerCase()
    return boardSquareColors[color]?.lightSquare || boardColors.blue.lightSquare
  },
  toggleSounds: () => {
    set((state) => ({ playSounds: !state.playSounds }))
  },
  updateActivityStatus: (isIncognito) => {
    set({ isIncognito: isIncognito })
    // update user object with latest incognito settings
    useAuthStore.setState({
      ...useAuthStore.getState(),
      user: {
        ...useAuthStore.getState().user,
        isIncognito,
      },
    })
  },
  toggleAnimations: () => {
    if (get().pieces === 'Animated') {
      set((state) => ({ playAnimations: !state.playAnimations }))
    }
  },
  toggleEvaluationBar: () => {
    set((state) => ({ showEvaluationBar: !state.showEvaluationBar }))
  },
  updatePieces: (type) => {
    if (type === 'Animated') {
      set({ pieces: type })
    } else {
      set({ pieces: type, playAnimations: false })
    }
  },
  updateBoardcolor: (color) => {
    set({ boardColor: color })
  },
  updateBoardType: (type) => {
    set({ boardType: type })
  },
  currentBg: () => withoutVariant(get().background),
  updateBackgroundImage: (background) => {
    set({ background: `${background}-${get().bgVariant}` })
  },
  updateBgVariant: (value) => {
    const currentBg = withoutVariant(get().background)

    set({
      bgVariant: value,
      background: `${currentBg}-${value}`,
    })
  },
  reset: () => {
    set({
      playSounds: defaultState.playSounds,
      playAnimations: defaultState.playAnimations,
      showEvaluationBar: defaultState.showEvaluationBar,
      pieces: defaultState.pieces,
      boardColor: defaultState.boardColor,
      bgVariant: defaultState.bgVariant,
      background: defaultState.background,
      isIncognito: defaultState.isIncognito,
    })
  },
})

const withoutVariant = (bg) => {
  return bg.substring(0, bg.lastIndexOf('-'))
}

const useSettingsStore = create(persist(store, { name: 'settings' }))

export default useSettingsStore

export const availableColors = boardColors
