export const randomId = () => {
  return Math.floor(
    Math.random() * (Date.now() - 100000000) + 100000000,
  ).toString(36)
}

export const getRandomIds = (count = 100) => {
  const ids = []

  for (let i = 0; i < count; i++) {
    ids.push(randomId())
  }

  return ids
}
