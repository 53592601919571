import { useState, useEffect } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  CloseButton,
  SlideFade,
  useMediaQuery,
} from '@chakra-ui/react'

import { useSocket } from 'context/socket-context'

const BroadcastGameAlert = () => {
  const socket = useSocket()
  const [broadcastGame, setBroadcastGame] = useState()
  const [isHidden, setIsHidden] = useState(false)
  const [isSmallScreen] = useMediaQuery('(max-width: 640px)')

  const hideAlert = () => setIsHidden(true)

  const onWatchPage = useRouteMatch({ path: '/watch' })

  const alertMsg = () => {
    if (!broadcastGame?.header || isSmallScreen) {
      return 'Game is now being broadcasted...'
    }

    const { White, Black } = broadcastGame.header
    return `${White} vs ${Black} is now being broadcasted...`
  }

  useEffect(() => {
    socket.emit('get-broadcast-game')
    socket.on('broadcast-game', (game) => {
      setBroadcastGame(game)
    })

    return () => {
      socket.off('get-broadcast-game')
    }
  }, [])

  if (!broadcastGame || onWatchPage || isHidden) {
    return null
  }

  return (
    <SlideFade initialScale={0.9} in={true} offsetY="-24px">
      <Alert status="info" justifyContent="center" textColor="blue.700">
        <AlertIcon />
        <AlertDescription fontSize="sm" pr={4}>
          {alertMsg()}

          <Button
            as={Link}
            to="/watch?broadcast-game=true"
            colorScheme="blue"
            size="sm"
            ml={2}
          >
            Watch
          </Button>
        </AlertDescription>
        <CloseButton
          position="absolute"
          right="8px"
          top="8px"
          onClick={hideAlert}
        />
      </Alert>
    </SlideFade>
  )
}

export default BroadcastGameAlert
