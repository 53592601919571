import { Howl } from 'howler'

const soundEffects = {
  alert() {
    const alertSound = new Howl({
      src: '/audio/alert.mp3',
      html5: true,
    })
    alertSound.play()
  },
  winner() {
    const youWinSound = new Howl({
      src: '/audio/youwin.mp3',
      html5: true,
    })
    youWinSound.play()
  },
  loser() {
    const youLoseSound = new Howl({
      src: '/audio/youlose.mp3',
      html5: true,
    })
    youLoseSound.play()
  },
  move() {
    const moveSound = new Howl({
      src: '/audio/move2.mp3',
      html5: true,
    })
    moveSound.play()
  },
  capture() {
    const moveSound = new Howl({
      src: '/audio/capture.mp3',
      html5: true,
    })
    moveSound.play()
  },
  promotion() {
    const promotionSound = new Howl({
      src: '/audio/promotion.mp3',
      html5: true,
    })
    promotionSound.play()
  },
  challenge() {
    const challengeSound = new Howl({
      src: '/audio/challenge.wav',
      html5: true,
    })
    challengeSound.play()
  },
  play: (src) => {
    const sound = new Howl({
      src: src,
      html5: true,
    })
    sound.play()
  },
}

export { soundEffects }
