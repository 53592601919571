import axios from 'lib/axios'

export const createTournament = async () => {}

export const getCompletedTournaments = async () => {
  const { data } = await axios.get('/api/tournaments/all')
  return data
}

export const deleteTournament = async (id) => {}

export const registerForTournament = async (id) => {}

export const unregisterForTournament = async (id) => {}
