import { Avatar, Box, Text, Flex } from '@chakra-ui/react'

const RegisteredByAdmin = ({ admin, text }) => {
  return (
    <Box bg="green.100" p={4} rounded="md" shadow="lg">
      <Flex align="center" direction="column">
        <Avatar size="lg" src={admin.avatar} mr={2} />
        <Text fontWeight="bold" color="green.700" mt={-2}>
          {admin.username}
        </Text>
      </Flex>
      <Text mt={2} color="green.900" fontSize="lg">
        {text}
      </Text>
    </Box>
  )
}

export default RegisteredByAdmin
