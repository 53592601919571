import create from 'zustand'
import useAuthStore from 'store/useAuthStore'

const useOnlineUsers = create((set, get) => ({
  count: 0,
  users: [],
  updateOnlineUsers: (usersData = []) => {
    const user = useAuthStore.getState().user
    const updateAvatar = useAuthStore.getState().updateUserAvatar

    let count = 0
    const users = usersData.reduce((acc, cur) => {
      if (!cur.isIncognito) {
        count++
      }

      if (cur.userId === user?.userId) {
        acc.unshift(cur)

        // update avatar in state if it has changed on server
        if (cur.avatar !== user.avatar) {
          updateAvatar(cur.avatar)
        }
      } else {
        acc.push(cur)
      }

      return acc
    }, [])

    set({ count, users })
  },
}))

export default useOnlineUsers
