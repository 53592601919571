import axios from 'lib/axios'

export const createPanicReport = (panicData) =>
  axios.post('/api/panic', panicData)

export const getGuardiansPanicReports = async (userId) => {
  const response = await axios.get(`/api/panic/get/${userId}`)

  return response.data
}

export const updatePanicReport = async ({ id, child, isHandled }) => {
  return axios.post('/api/panic/handle', {
    panicId: id,
    childId: child,
    falseAlarm: isHandled,
  })
}

const getPanicReportsForReview = async () => {
  const response = await axios.get('/api/panic/reviews')

  return response.data
}

export const getPanicReports = async ({ needsReview, isHandled }) => {
  if (needsReview) {
    return getPanicReportsForReview()
  }

  const { data } = await axios.get('/api/panic/all')

  if (isHandled) {
    return data.filter((report) => report.isHandled)
  }

  return data
}
