import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { handleAuthentication, isPremiumUser } from 'services/auth'
import { setAxiosInterceptors } from 'lib/axios'
import useAuthStore from 'store/useAuthStore'
import LoadingScreen from '../LoadingScreen'

// Restore user session if we have a valid token and update premium status.
const Auth = ({ children }) => {
  const history = useHistory()
  const logout = useAuthStore((state) => state.logout)
  const updatePremiumStatus = useAuthStore((state) => state.updatePremiumStatus)
  const accessToken = useAuthStore((state) => state.accessToken)

  const [isLoading, setLoading] = useState(true)

  async function restoreSession() {
    handleAuthentication(accessToken)

    try {
      const isPremium = await isPremiumUser()
      updatePremiumStatus(isPremium)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      logout()
    }
  }

  useEffect(() => {
    setAxiosInterceptors({
      onLogout: () => {
        logout()
        history.push('/')
      },
    })

    if (accessToken) {
      restoreSession()
    } else {
      setLoading(false)
    }
  }, [logout, accessToken, history])

  if (isLoading) {
    return <LoadingScreen />
  }

  return children
}

export default Auth
