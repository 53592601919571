import { Box } from '@chakra-ui/react'

import GuestNav from './GuestNav'
import GuestFooter from './GuestFooter'
import Banner from './Banner'

function GuestLayout(props) {
  return (
    <Box minH="100vh">
      <Banner />
      <GuestNav />
      <main>{props.children}</main>
      <GuestFooter />
    </Box>
  )
}

export default GuestLayout
