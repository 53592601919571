import { useLocation } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import Lottie from 'react-lottie-player'

import logo from 'animations/logo.json'
import PuzzleInfoPopover from 'components/Question/PuzzleInfoPopover'

export default function AnimatedLogo(props) {
  const { pathname } = useLocation()

  if (pathname.startsWith('/puzzles')) {
    return (
      <PuzzleInfoPopover mode={pathname.slice(pathname.lastIndexOf('/') + 1)}>
        <Box h={24} w={24} {...props} cursor="pointer" zIndex={9}>
          <Lottie animationData={logo} play />
        </Box>
      </PuzzleInfoPopover>
    )
  }

  return (
    <Box h={24} w={24} {...props} zIndex={9}>
      <Lottie animationData={logo} play />
    </Box>
  )
}
