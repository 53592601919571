import { useToast } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryCache } from 'react-query'

import {
  getFriends,
  getUsersFriends,
  getUsersFriendsStatus,
  unfriend,
  sendFriendRequest,
} from 'services/friends'

export const useFriends = () => {
  return useQuery('friends', getFriends, {
    initialData: [],
    forceFetchOnMount: true,
  })
}

export const useGetUsersFriends = (userId) => {
  return useQuery(`friends-${userId}`, () => getUsersFriends(userId), {
    enabled: !!userId,
    initialData: [],
    forceFetchOnMount: true,
  })
}

export const useFriendsCheck = (user1, user2) => {
  return useQuery(`friends-${user1}-${user2}`, () =>
    getUsersFriendsStatus(user1, user2),
  )
}

export const useSendFriendRequest = ({ onSuccess, onError }) => {
  const toast = useToast()
  const queryCache = useQueryCache()

  return useMutation(sendFriendRequest, {
    onSuccess: (data) => {
      queryCache.invalidateQueries()

      toast({
        status: 'success',
        title: data.message,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    onError: (error) => {
      if (onError) {
        onError()
      }

      toast({
        status: 'error',
        title: error?.response?.data?.error || 'Error sending friend request',
      })
    },
  })
}

export const useUnfriend = (onSuccess) => {
  const toast = useToast()
  const queryCache = useQueryCache()

  return useMutation(unfriend, {
    onSuccess: (data) => {
      queryCache.invalidateQueries()
      toast({
        status: 'success',
        title: data.message,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    onError: (error) => {
      toast({
        status: 'error',
        title: error?.response?.data?.error || 'Error unfriending user',
      })
    },
  })
}
