export const addVirtualBackgrounds = (client) => {
  if (client?.isSupportVirtualBackground()) {
    return client.updateVirtualBackgroundList([
      {
        id: 'simpsons',
        displayName: 'Simpsons',
        url: 'https://res.cloudinary.com/dgyxq8dhq/image/upload/v1676653608/zoom-backgrounds/5f691b0fe305260086535c23_The-Simpsons-Zoom-Background_wne2bk.jpg',
        fileName: 'Simpsons',
      },
      {
        id: 'island',
        displayName: 'Island',
        url: 'https://res.cloudinary.com/dgyxq8dhq/image/upload/v1676653879/zoom-backgrounds/rshty56_xjubkg.jpg',
        fileName: 'Island',
      },
      {
        id: 'planets',
        displayName: 'planets',
        url: 'https://res.cloudinary.com/dgyxq8dhq/image/upload/v1676653873/zoom-backgrounds/plasad_ctvuye.jpg',
        fileName: 'planets',
      },
      {
        id: 'c1',
        displayName: 'Chess 1',
        url: 'https://images.unsplash.com/photo-1529699211952-734e80c4d42b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80',
        fileName: 'Chess 1',
      },
      {
        id: 'c2',
        displayName: 'Chess 2',
        url: 'https://images.unsplash.com/photo-1586165368502-1bad197a6461?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1258&q=80',
        fileName: 'Chess 2',
      },
      {
        id: 'c3',
        displayName: 'Chess 3',
        url: 'https://images.unsplash.com/photo-1543092587-d8b8feaf362b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
        fileName: 'Chess 3',
      },
      {
        id: 'c4',
        displayName: 'Chess 4',
        url: 'https://images.unsplash.com/photo-1608053001983-96458c0a868c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
        fileName: 'Chess 4',
      },
      {
        id: 'g5w38rwrk9',
        displayName: 'Disney Castle',
        url: 'https://res.cloudinary.com/dgyxq8dhq/image/upload/v1686565334/zoom-backgrounds/g5w38rwrk9.jpg',
        fileName: 'Disney Castle',
      },
      {
        id: '38q29s3ily',
        displayName: 'Moon Landing',
        url: 'https://res.cloudinary.com/dgyxq8dhq/image/upload/v1686565316/zoom-backgrounds/38q29s3ily.jpg',
        fileName: 'Moon Landing',
      },
      {
        id: 'iz63ecp2im',
        displayName: 'Room',
        url: 'https://res.cloudinary.com/dgyxq8dhq/image/upload/v1686565303/zoom-backgrounds/iz63ecp2im.jpg',
        fileName: 'Room',
      },
      {
        id: 'ot4eu8d6bq',
        displayName: 'Minions',
        url: 'https://res.cloudinary.com/dgyxq8dhq/image/upload/v1686565288/zoom-backgrounds/ot4eu8d6bq.jpg',
        fileName: 'Minions',
      },
      {
        id: '5rex2l8vra',
        displayName: 'Leaning Tower of Pisa',
        url: 'https://res.cloudinary.com/dgyxq8dhq/image/upload/v1686565265/zoom-backgrounds/5rex2l8vra.jpg',
        fileName: 'Leaning Tower of Pisa',
      },
      {
        id: 'bf8pl6gks',
        displayName: 'Disney',
        url: 'https://res.cloudinary.com/dgyxq8dhq/image/upload/v1686565243/zoom-backgrounds/bf8pl6gks.jpg',
        fileName: 'Disney',
      },
      {
        id: 'z5lrmyt7oy',
        displayName: 'Pyramid',
        url: 'https://res.cloudinary.com/dgyxq8dhq/image/upload/v1686565222/zoom-backgrounds/z5lrmyt7oy.jpg',
        fileName: 'Pyramid ',
      },
      {
        id: 'r9igjtbsi1',
        displayName: 'Room 2',
        url: 'https://res.cloudinary.com/dgyxq8dhq/image/upload/v1686565222/zoom-backgrounds/r9igjtbsi1.jpg',
        fileName: 'Room 2 ',
      },
      {
        id: 'szctf4bdnq',
        displayName: 'Forrest House',
        url: 'https://res.cloudinary.com/dgyxq8dhq/image/upload/v1686565222/zoom-backgrounds/szctf4bdnq.jpg',
        fileName: 'Forrest house',
      },
    ])
  }
}
