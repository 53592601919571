import { Box, Button, Flex, Heading, Text, Image } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import Container from 'layouts/Container'
import { trackEventSlack } from 'services/postSlackMessage'

const MotionButton = motion(Button)

export default function Features() {
  const handleVrLinkClick = () => {
    const alreadyTracked = sessionStorage.getItem('vrLinkClicked')

    if (!alreadyTracked) {
      trackEventSlack('New VR website vistor from Landing Page link').catch()
      sessionStorage.setItem('vrLinkClicked', 'true')
    }

    window.open('https://vr.chesswithknarik.com', '_blank')
  }

  return (
    <Container mt={{ lg: 12 }}>
      <Flex align="flex-start" position="relative" justify={{ md: 'center' }}>
        <Box
          w={{ base: '70%', md: '55%', lg: '48%' }}
          ml={{ sm: 12, md: 0 }}
          h={{ lg: '400px' }}
        >
          <Image
            src="/images/kidVR.png"
            w={{ base: '200px', sm: '320px', md: '400px', lg: '480px' }}
          />
        </Box>
        <Box
          maxW={{ base: '240px', md: 'full' }}
          mt={{ base: -40, md: 0 }}
          position={{ base: 'absolute', md: 'relative' }}
          top={12}
          right={0}
        >
          <Heading
            as="h2"
            color="#2D81A5"
            fontSize={{ base: '3xl', md: '5xl' }}
          >
            New!
          </Heading>
          <Text fontSize={{ base: 'lg', md: '3xl' }} maxW={{ md: '350px' }}>
            Now you can play chess in Virtual Reality!
          </Text>
          <MotionButton
            ml={{ base: 24, md: 0 }}
            mt={{ base: 8, md: 4 }}
            colorScheme="green"
            color="white"
            py={{ md: 8 }}
            fontSize={{ base: 'xl', md: '3xl' }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleVrLinkClick}
          >
            Try it out
          </MotionButton>
        </Box>
      </Flex>
    </Container>
  )
}
