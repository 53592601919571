import { useEffect } from 'react'
import Bowser from 'bowser'

const useBrowserInfo = (socket, state) => {
  useEffect(() => {
    const userAgent = Bowser.getParser(window.navigator.userAgent)
    const { parsedResult: { browser } } = userAgent
    socket.emit('getTypeOfBrowser', {
      browser: browser.name.toLowerCase(),
      color: state?.color,
      roomId: state?.id,
    })
  }, [socket, state?.color, state?.id])
}

export default useBrowserInfo