const boardSquareColors = {
  blue: { lightSquare: 'AliceBlue', darkSquare: '#a1bff4' },
  brown: { lightSquare: '#F0D9B5', darkSquare: '#B58863' },
  orange: { lightSquare: '#fce7dc', darkSquare: '#f7bd9d' },
  gray: { lightSquare: '#f6f6f6', darkSquare: '#c3c3c3' },
  pink: { lightSquare: '#ffb4ee', darkSquare: '#ff5fdb' },
  transparent: {
    lightSquare: 'rgba(255, 255, 255, 0.55)',
    darkSquare: 'rgba(0, 0, 0, 0.55)',
  },
}

export default boardSquareColors
