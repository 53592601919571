import { useRef, useEffect, useState } from 'react'
import {
  Avatar,
  CircularProgress,
  Button,
  Box,
  Icon,
  Text,
  Flex,
  HStack,
} from '@chakra-ui/react'
import Lottie from 'react-lottie-player'
import ms from 'ms'
import { BsClockHistory } from 'react-icons/bs'
import { GiCheckMark } from 'react-icons/gi'

import challengeAnimation from 'animations/challenge.json'
import ChallengeColorIcon from './ChallengeColorIcon'

const ChallengeNotification = ({ challenge, onAccept, onDecline, onClose }) => {
  const [state, setState] = useState({ isAccepting: false, isDeclining: false })

  const declineHandler = () => {
    setState((prev) => ({ ...prev, isDeclining: true }))
    onDecline(challenge, () => {
      setState((prev) => ({ ...prev, isDeclining: false }))
      onClose()
    })
  }

  const acceptHandler = () => {
    setState((prev) => ({ ...prev, isAccepting: true }))
    onAccept(challenge, () => {
      setState((prev) => ({ ...prev, isAccepting: false }))
      onClose()
    })
  }

  return (
    <Box
      mt={4}
      rounded="md"
      bg="gray.100"
      py={2}
      px={{ base: 1, md: 4 }}
      position="relative"
      boxShadow="0px 10px 40px -5px rgba(0, 0, 0, .9)"
      borderWidth={3}
      borderColor="gray.300"
      _hover={{ borderColor: 'blue.300' }}
    >
      <Box position="absolute" top={-8} right={2}>
        <ChallengeAnimation duration={30000} onComplete={onClose} />
      </Box>
      <Flex align="center">
        <Flex direction="column" align="center">
          <Avatar w={24} h={24} src={challenge.from.avatar} />
          <Box
            fontWeight="semibold"
            fontSize="sm"
            rounded="md"
            bg="green.400"
            color="green.100"
            px={2}
            py={0.5}
            mt={-2}
            zIndex={3}
            lineHeight={1}
          >
            {challenge.from.rating}
          </Box>
        </Flex>
        <Box px={2} maxW={{ md: '320px' }}>
          <Text fontSize="xl" textColor="gray.600" fontWeight="extrabold">
            Challenge!
          </Text>

          <Text textColor="gray.500" lineHeight={1.1}>
            {challenge.from.username} challenged you to a{' '}
            {challenge?.isRematch ? 'rematch!' : 'game!'}
          </Text>
          <Flex align="center" justify="space-between" mt={2}>
            <Flex align="center" textColor="gray.500" fontSize="sm">
              <Text>{challenge.gameType} | </Text>
              <Flex align="center">
                <Icon
                  color="blackAlpha.700"
                  rounded="full"
                  as={BsClockHistory}
                  mx={1}
                  w={6}
                  h={6}
                />
                {ms(challenge.clock)} |
              </Flex>
              <ChallengeColorIcon
                h={7}
                w={7}
                iconSize={4}
                color={challenge?.color}
                tooltip
              />
            </Flex>
            <HStack align="flex-end" justify="flex-end" ml={6}>
              <Button
                rounded="full"
                size="sm"
                colorScheme="red"
                onClick={declineHandler}
                px={0}
                isDisabled={state.isAccepting}
                isLoading={state.isDeclining}
              >
                X
              </Button>
              <Button
                rounded="full"
                size="sm"
                colorScheme="green"
                onClick={acceptHandler}
                px={0}
                isDisabled={state.isDeclining}
                isLoading={state.isAccepting}
              >
                <GiCheckMark />
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

const ChallengeAnimation = ({ duration, onComplete }) => {
  const lottieRef = useRef()
  const [progress, setProgress] = useState(100)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => prevProgress - 1)
    }, duration / 100)

    return () => clearInterval(interval)
  }, [duration])

  useEffect(() => {
    if (progress === 0) {
      onComplete()
    }
  }, [progress, onComplete])

  useEffect(() => {
    lottieRef.current?.play()
  }, [])

  return (
    <Box position="relative" w={16} h={16}>
      <CircularProgress
        value={progress}
        size={16}
        position="absolute"
        color="green.300"
      />
      <Box
        w={12}
        h={12}
        position="absolute"
        bg="gray.100"
        rounded="full"
        top={2}
        left={2}
      >
        <Lottie
          ref={lottieRef}
          animationData={challengeAnimation}
          style={{ height: '100%' }}
          loop={1}
        />
      </Box>
    </Box>
  )
}

export default ChallengeNotification
