import create from 'zustand'
import { persist } from 'zustand/middleware'

import { signin, signup } from 'services/auth'
import { removeTokenHeader } from 'lib/axios'
import useSettingsStore from './useSettings'

const store = (set, get) => ({
  accessToken: null,
  user: null,
  redirect: null,
  completeRegistration: () => {
    const user = {
      ...get().user,
      completedSignupQuiz: true,
    }
    set({ user })
  },
  updateUserScore: (newScore) => {
    const user = { ...get().user, score: newScore, rating: newScore }
    set({ user })
  },
  updateUserAvatar: async (newAvatar) => {
    const user = { ...get().user, avatar: newAvatar }
    set({ user })
  },
  updatePremiumStatus: (isPremium) => {
    const user = { ...get().user, isPremium }
    set({ user })
  },
  signup: async ({
    username,
    firstName,
    surname,
    email,
    password,
    avatar,
    isChild,
    promoCode,
    premiumSignup,
  }) => {
    const { accessToken, user } = await signup({
      username,
      firstName,
      surname,
      email,
      password,
      avatar,
      isChild,
      promoCode,
    })

    let redirect = '/settings?change-username=true'
    if (premiumSignup && !user.isPremium) {
      redirect = '/premium-signup'
    }

    set({
      user,
      accessToken,
      redirect,
    })
  },
  login: async (username, password) => {
    const { accessToken, user, userSettings } = await signin(username, password)

    user.isIncognito = userSettings?.incognito || false

    set({ user, accessToken })

    if (userSettings) {
      // update settings
      useSettingsStore.setState({
        background: `${userSettings.bg}-${userSettings.mode}`,
        bgVariant: userSettings.mode,
        playSounds: userSettings.sounds,
        playAnimations: userSettings.animations,
        showEvaluationBar: userSettings.evalBar,
        pieces: userSettings.pieces,
        boardColor: userSettings.board,
        isIncognito: userSettings.incognito,
      })
    }
  },
  logout: () => {
    // clear state
    set({ accessToken: null, user: null, redirect: null })
    useSettingsStore.getState().reset()

    // clear local storage
    localStorage.removeItem('auth')
    localStorage.removeItem('settings')

    // clearing session (zoom stores user email and name)
    sessionStorage.clear()

    // clear saved token from axios
    removeTokenHeader()
  },
})

const useAuthStore = create(
  persist(store, {
    name: 'auth',
    partialize: (state) => ({
      user: state.user,
      accessToken: state.accessToken,
    }),
  }),
)

export default useAuthStore
