import axios from 'axios'

function postSlackMessage(msg) {
  try {
    axios.post(
      process.env.REACT_APP_SLACK_WEBHOOK_URL,
      {
        text: msg,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
  } catch (err) {
    console.error('Error posting slack message')
  }
}

export const trackEventSlack = (text) => {
  if (process.env.REACT_APP_ENV !== 'production') return Promise.resolve()

  return axios.post(
    process.env.REACT_APP_SLACK_EVENTSWEBHOOK_URL,
    { text },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  )
}

export default postSlackMessage
