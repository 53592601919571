import { Box, Button, Flex, Text } from '@chakra-ui/react'
import Lottie from 'react-lottie-player'

import trophyAnimation from 'animations/trophy.json'
import { registerForTournament } from 'services/tournaments'

const NewTournamentAlert = ({ tournament }) => {
  const handleRegistration = async () => {
    try {
      await registerForTournament()
    } catch (err) {
      alert('Error registering for tournament!')
    }
  }

  return (
    <Box
      bg="green.100"
      W="5xl"
      py={8}
      px={4}
      rounded="md"
      mt={-32}
      shadow="lg"
      position="relative"
    >
      <Flex>
        <Box h={48} w={48} align="center">
          <Lottie
            animationData={trophyAnimation}
            loop={true}
            style={{
              height: '200%',
              width: '200%',
              marginLeft: '-50%',
              marginTop: '-50%',
            }}
            play
          />
        </Box>
        <Box ml={6} flex={1} py={4}>
          <Text fontSize="3xl" fontWeight="extrabold" color="green.600">
            New Tournament!
          </Text>
          <Text mt={2} color="green.700">
            A new tournament has been created and is scheduled to start in 10
            minutes.{' '}
          </Text>
          <Button mt={4} colorScheme="orange" onClick={handleRegistration}>
            Register
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export default NewTournamentAlert
