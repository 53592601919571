import { Link, useLocation } from 'react-router-dom'
import {
  Alert,
  AlertIcon,
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import ms from 'ms'
import { IoMdNotifications } from 'react-icons/io'
import SimpleBar from 'simplebar-react'
import { BsClockHistory } from 'react-icons/bs'

import dayjs from 'lib/dayjs'
import {
  useAcceptFriendRequest,
  useDeclineFriendRequest,
  useGetPendingFriends,
} from 'hooks/friendRequests'
import {
  useChallenges,
  useAcceptChallenge,
  useDeclineChallenge,
  useCancelChallenge,
} from 'hooks/challenges'
import 'simplebar/src/simplebar.css'
import ChallengeColorIcon from 'components/challenges/ChallengeColorIcon'
import useBrowserInfo from '../hooks/useBrowserInfo'
import { useSocket } from '../context/socket-context'

export default function Notifications() {
  const { data: friendRequests } = useGetPendingFriends()
  const { data: challenges } = useChallenges()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const socket = useSocket()
  const { state } = useLocation()

  useBrowserInfo(socket, state)

  const notifcations =
    (friendRequests?.length || 0) +
    (challenges?.in?.length || 0) +
    (challenges?.out?.length || 0)

  return (
    <Box ml={2}>
      <Popover
        onClose={onClose}
        onOpen={onOpen}
        isOpen={isOpen}
        placement="bottom"
        gutter={12}
      >
        <>
          <PopoverTrigger>
            <Box
              as="button"
              bg="blackAlpha.100"
              _hover={{ bg: 'blackAlpha.400' }}
              _focus={{ bg: 'blackAlpha.400' }}
              color="white"
              p={2}
              rounded="full"
              position="relative"
            >
              <IoMdNotifications size={22} />
              {notifcations > 0 && (
                <Box
                  position="absolute"
                  top={-2}
                  right={-2}
                  bg="green.500"
                  rounded="full"
                  px={1.5}
                  py={1}
                  textAlign="center"
                  color="white"
                  fontWeight="bold"
                  fontSize="xs"
                  minW="20px"
                  lineHeight={1}
                >
                  {notifcations}
                </Box>
              )}
            </Box>
          </PopoverTrigger>

          <PopoverContent color="gray.700" width="320px">
            <Box py={4} px={2}>
              {notifcations === 0 && (
                <Alert status="info" rounded="lg" color="blue.600">
                  <AlertIcon />
                  No Notifications
                </Alert>
              )}

              {notifcations > 0 && (
                <SimpleBar
                  style={{
                    height: '100%',
                    maxHeight: '360px',
                    overflowX: 'hidden',
                  }}
                >
                  <VStack>
                    {friendRequests?.length > 0 && (
                      <Box w="full">
                        <Flex align="center" justify="center">
                          <Text fontWeight="semibold"> Friend Requests</Text>
                          <Badge ml={1}>{friendRequests.length}</Badge>
                        </Flex>

                        <Stack mt={1}>
                          {friendRequests.map((friendRequest) => (
                            <FriendRequest
                              key={Math.random()}
                              request={friendRequest}
                              onSuccess={
                                friendRequests.length === 1
                                  ? onClose
                                  : undefined
                              }
                            />
                          ))}
                        </Stack>
                      </Box>
                    )}

                    {challenges?.in?.length > 0 && (
                      <Box w="full">
                        <Flex align="center" justify="center">
                          <Text fontWeight="semibold">Incoming challenges</Text>
                          <Badge ml={1}>{challenges.in.length}</Badge>
                        </Flex>
                        {challenges.in.map((challenge) => (
                          <Challenge key={challenge.id} challenge={challenge} />
                        ))}
                      </Box>
                    )}

                    {challenges?.out?.length > 0 && (
                      <Box w="full">
                        <Flex align="center" justify="center">
                          <Text textAlign="center" fontWeight="semibold">
                            Sent challenges
                          </Text>
                          <Badge ml={1}>{challenges.out.length}</Badge>
                        </Flex>
                        {challenges.out.map((challenge) => (
                          <Challenge
                            key={challenge.id}
                            challenge={challenge}
                            isOutgoing
                          />
                        ))}
                      </Box>
                    )}
                  </VStack>
                </SimpleBar>
              )}
            </Box>
          </PopoverContent>
        </>
      </Popover>
    </Box>
  )
}

function FriendRequest({ request, onSuccess }) {
  const [accept, { status: acceptStatus }] = useAcceptFriendRequest(onSuccess)
  const [decline, { status: declineStatus }] =
    useDeclineFriendRequest(onSuccess)

  return (
    <Box
      boxShadow="lg"
      borderRadius="lg"
      borderWidth={1}
      m={'4px !important'}
      _hover={{
        borderColor: 'blue.200',
        transform: 'scale(1.02)',
        transition: 'transform 200ms',
      }}
    >
      <Stack direction="row" color="gray.700">
        <Stack spacing="0" p="2" flex={1} align="flex-start">
          <Flex align="center" maxW="190px" overflow="hidden">
            <Flex direction="column" align="center">
              <Avatar
                as={Link}
                to={`/user/${request.user.id}/profile`}
                src={request.user.avatar}
              />
              <Box
                fontWeight="semibold"
                fontSize="xs"
                rounded="md"
                bg="green.400"
                color="green.100"
                px={2}
                mt={-2}
                zIndex={3}
                lineHeight={1.2}
              >
                {request.user.rating}
              </Box>
            </Flex>
            <Box lineHeight={1.1} ml={2}>
              <Text color="gray.600" fontSize="sm" fontWeight="semibold">
                {request.user.username}
              </Text>
              <Text color="gray.500" fontSize="xs">
                {dayjs().to(request.date)}
              </Text>
            </Box>
            <Text
              lineHeight={1.1}
              ml={2}
              color="gray.600"
              fontSize="sm"
              fontWeight="semibold"
            ></Text>
          </Flex>
        </Stack>
        <Stack align="center" spacing={0}>
          <Button
            h="full"
            size="sm"
            colorScheme="green"
            variant="ghost"
            onClick={() => accept(request.id)}
            isLoading={acceptStatus === 'loading'}
            isDisabled={declineStatus === 'loading'}
          >
            Accept
          </Button>
          <Button
            h="full"
            size="sm"
            colorScheme="red"
            variant="ghost"
            onClick={() => decline(request.id)}
            isLoading={declineStatus === 'loading'}
            isDisabled={acceptStatus === 'loading'}
          >
            Decline
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

function Challenge({ challenge, isOutgoing }) {
  const { acceptChallenge, status: acceptStatus } = useAcceptChallenge()
  const { declineChallenge, status: declineStatus } = useDeclineChallenge()
  const { cancelChallenge, status: cancelStatus } = useCancelChallenge()

  const displayUser = isOutgoing ? challenge.to : challenge.from

  return (
    <Box
      boxShadow="lg"
      borderRadius="lg"
      borderWidth={1}
      m={'4px !important'}
      _hover={{
        borderColor: 'blue.200',
        transform: 'scale(1.02)',
        transition: 'transform 200ms',
      }}
    >
      <Stack direction="row" color="gray.700">
        <Stack
          spacing="0"
          p="2"
          flex={1}
          align="flex-start"
          maxW="240px"
          overflow="hidden"
        >
          <Flex align="center">
            <Flex direction="column" align="center">
              <Avatar
                as={Link}
                to={`/user/${displayUser.id}/profile`}
                src={displayUser.avatar}
              />
              <Box
                fontWeight="semibold"
                fontSize="xs"
                rounded="md"
                bg="green.400"
                color="green.100"
                px={2}
                mt={-2}
                zIndex={3}
                lineHeight={1.2}
              >
                {displayUser.rating}
              </Box>
            </Flex>

            <Box lineHeight={1.1} ml={2}>
              <Text color="gray.600" fontSize="sm" fontWeight="semibold">
                {displayUser.username}
              </Text>
              <Text color="gray.500" fontSize="xs">
                {dayjs().to(challenge.createdAt)}
              </Text>
            </Box>
          </Flex>
          <Flex
            align="center"
            textColor="gray.500"
            fontSize="xs"
            justify="center"
          >
            <Text>{challenge.gameType} | </Text>
            <Flex align="center">
              <Icon
                color="blackAlpha.700"
                rounded="full"
                as={BsClockHistory}
                mx={1}
                w={6}
                h={6}
              />
              {ms(challenge.clock)} |
            </Flex>
            <ChallengeColorIcon
              h={7}
              w={7}
              iconSize={4}
              color={challenge?.color}
              tooltip
            />
          </Flex>
        </Stack>
        <Stack align="center" spacing={0}>
          {isOutgoing && (
            <Button
              h="full"
              size="sm"
              colorScheme="red"
              variant="ghost"
              onClick={() => cancelChallenge(challenge.id)}
              isLoading={cancelStatus === 'loading'}
              isDisabled={cancelStatus === 'loading'}
            >
              Cancel
            </Button>
          )}
          {!isOutgoing && (
            <>
              <Button
                h="full"
                size="sm"
                colorScheme="green"
                variant="ghost"
                onClick={() => acceptChallenge(challenge)}
                isLoading={acceptStatus === 'loading'}
                isDisabled={declineStatus === 'loading'}
              >
                Accept
              </Button>
              <Button
                h="full"
                size="sm"
                colorScheme="red"
                variant="ghost"
                onClick={() => declineChallenge(challenge)}
                isLoading={declineStatus === 'loading'}
                isDisabled={acceptStatus === 'loading'}
              >
                Decline
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}
