import { Redirect } from 'react-router-dom'

import useAuthStore from 'store/useAuthStore'
import useConnection from 'store/useShowClosedConnection'

function AuthGuard({ children }) {
  const user = useAuthStore((state) => state.user)
  const isClosed = useConnection((state) => state.isClosed)

  if (!user) {
    return <Redirect to="/" />
  }

  if (!isClosed && user.completedSignupQuiz) {
    return <Redirect to="/play" />
  }

  return <> {children} </>
}

export default AuthGuard
