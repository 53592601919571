import { Link, useRouteMatch } from 'react-router-dom'
import { Box, HStack, Text, Flex } from '@chakra-ui/react'
import { AiFillDashboard } from 'react-icons/ai'
import { BsQuestionDiamondFill, BsFillPencilFill } from 'react-icons/bs'
import { ImUsers } from 'react-icons/im'
import { GiTeacher } from 'react-icons/gi'
import { SlBadge } from 'react-icons/sl'
import { FaChessBoard } from 'react-icons/fa'
import { RiCoupon3Line } from 'react-icons/ri'
import { GoReport } from 'react-icons/go'
import { TbTournament } from 'react-icons/tb'

import Container from './Container'
import { useGetPanicReports } from 'hooks/panic'
import { useStreaming } from 'store/useStreaminig'

const NavLink = ({ to, icon, children }) => {
  const isStreaming = useStreaming((state) => state.isStreaming)
  const isActive = useRouteMatch({
    path: `/admin/${to.split('/')[0]}`,
  })

  const inactiveColor = isStreaming ? 'whiteAlpha.800' : 'gray.600'

  return (
    <Link to={`/admin/${to}`}>
      <HStack
        bg={isActive ? 'orange.100' : 'transparent'}
        color={isActive ? 'orange.500' : inactiveColor}
        _hover={{
          bg: 'orange.100',
          color: 'orange.500',
        }}
        p={2}
        rounded="md"
        position="relative"
      >
        <Box as={icon} />
        <Text whiteSpace="nowrap" fontSize="sm" fontWeight="semibold">
          {children}
        </Text>
      </HStack>
    </Link>
  )
}

export default function AdminNav() {
  const { data } = useGetPanicReports({ needsReview: true })
  const isStreaming = useStreaming((state) => state.isStreaming)

  return (
    <Box bg="blackAlpha.200" fontWeight="semibold">
      <Container px={{ md: 2 }}>
        <Flex
          justify="space-between"
          align="center"
          direction={{ base: 'column', md: 'row' }}
          overflow="auto"
        >
          <HStack w="full">
            <NavLink to="dashboard" icon={AiFillDashboard}>
              Dashboard
            </NavLink>
            <NavLink to="questions" icon={BsQuestionDiamondFill}>
              Puzzles
            </NavLink>
            <NavLink to="users" icon={ImUsers}>
              Users
            </NavLink>
            <NavLink to="lessons" icon={GiTeacher}>
              Lessons
            </NavLink>
            <NavLink to="exercises" icon={BsFillPencilFill}>
              Exercises
            </NavLink>
            <NavLink to="rewards" icon={SlBadge}>
              Rewards
            </NavLink>
            <NavLink to="tournaments" icon={TbTournament}>
              Tournaments
            </NavLink>
            <NavLink to="classes" icon={GiTeacher}>
              Classes
            </NavLink>
            <NavLink to="class-board" icon={FaChessBoard}>
              Board
            </NavLink>
            <NavLink to="promo-codes" icon={RiCoupon3Line}>
              Promo Codes
            </NavLink>
            <NavLink to="panic-reports" icon={GoReport}>
              {data?.length > 0 && <CountBadge>{data.length}</CountBadge>}
              Panic Reports
            </NavLink>
          </HStack>
        </Flex>
      </Container>
    </Box>
  )
}

const CountBadge = ({ children }) => (
  <Box
    position="absolute"
    top={0}
    right={-2.5}
    bg={'green.500'}
    rounded="full"
    px={2}
    py={0.5}
    textAlign="center"
    color="white"
    fontWeight="bold"
    fontSize="xs"
  >
    {children}
  </Box>
)
