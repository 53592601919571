import React, { useEffect, useRef } from 'react'
import { Link, useRouteMatch, useLocation } from 'react-router-dom'
import {
  Box,
  CloseButton,
  Flex,
  HStack,
  IconButton,
  Image,
  Slide,
  Stack,
  Text,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

import UserMenu from './NavMenu'
import Container from './Container'
import AnimatedLogo from '../components/AnimatedLogo'

import useAuthStore from 'store/useAuthStore'
import useLiveClass from 'store/useLiveClass'
import OnlineUsersButton from './OnlineUsersButton'
import HintMessageBubble from 'components/HintMessageBubble'
import SettingsDrawerButton from 'components/SettingsDrawerButton'
import Notifications from './Notifications'
import Messages from './Messages'

const Links = [
  {
    label: 'Puzzles',
    to: '/puzzles/quiz',
  },
  {
    label: 'Lessons',
    to: '/lessons',
  },
  {
    label: 'Practice',
    to: '/practice',
  },
  {
    label: 'Play',
    to: '/play',
  },
  {
    label: 'Watch',
    to: '/watch',
  },
  // {
  //   label: 'Schedule',
  //   to: '/schedule',
  // },
]

const NavLink = ({ to, children }) => {
  const isActive = useRouteMatch({
    path: to.includes('admin') ? to.slice(0, to.lastIndexOf('/')) : to,
    exact: false,
  })

  return (
    <Link to={to}>
      <Box
        p={2}
        rounded={'md'}
        _hover={{
          textDecoration: 'none',
          bg: 'rgba(0, 0, 0, 0.25)',
        }}
        bg={isActive ? 'rgba(0, 0, 0, 0.25)' : 'default'}
      >
        <Text
          color="white"
          fontWeight="bold"
          fontSize={{ md: 'normal', xl: 'lg' }}
        >
          {children}
        </Text>
      </Box>
    </Link>
  )
}

export default function Nav() {
  const user = useAuthStore((state) => state.user)
  const inLiveClass = useLiveClass((state) => state.isLive)

  const location = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const ref = useRef()

  useOutsideClick({
    ref: ref,
    handler: onClose,
  })

  useEffect(() => {
    onClose()
  }, [location, onClose])

  return (
    <Box bg="blackAlpha.200" ref={ref}>
      <Container>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size="md"
            bg="transparent"
            color="white"
            icon={isOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
            aria-label={'Open Menu'}
            display={{ lg: 'none' }}
            p={3}
            onClick={isOpen ? onClose : onOpen}
            _hover={{
              bg: 'rgba(255, 255, 255, 0.1)',
            }}
          />
          <HStack spacing={{ lg: 4 }} alignItems={'center'}>
            <Box
              position="relative"
              zIndex={9}
              display={{ base: 'none', sm: 'block' }}
            >
              <AnimatedLogo />
              <HintMessageBubble />
            </Box>

            <HStack display={{ base: 'none', lg: 'flex' }} spacing={4}>
              {user?.isAdmin && (
                <NavLink to={'/admin/dashboard'}>Admin</NavLink>
              )}

              {Links.map((link) => (
                <NavLink key={link.to} to={link.to}>
                  {link.label}
                </NavLink>
              ))}

              {inLiveClass && <NavLink to="/live-class">Class Board</NavLink>}

              {user?.isAdmin && (
                <Box display={{ base: 'none', xl: 'block' }}>
                  <NavLink to={'/question/preview'}>View Question</NavLink>
                </Box>
              )}
            </HStack>
          </HStack>

          <HStack spacing={4}>
            <Messages />
            <Notifications />
            <OnlineUsersButton />
            <UserMenu />
            <SettingsDrawerButton />
          </HStack>
        </Flex>

        <Slide
          direction="top"
          in={isOpen}
          style={{ zIndex: 999 }}
          unmountOnExit={true}
          onClick={onClose}
        >
          <Box h="100vh" display={{ lg: 'none' }} bg="orange.400">
            <Stack as={'nav'} spacing={4}>
              <Flex p={4} justify="space-between" align="center" bg="white">
                <Image src="/images/logo.png" h={16} />

                <CloseButton color="orange.500" />
              </Flex>
              <Box px={4}>
                {user?.isAdmin && (
                  <NavLink to={'/admin/dashboard'}>Admin</NavLink>
                )}

                {Links.map((link) => (
                  <NavLink key={link.to} to={link.to}>
                    {link.label}
                  </NavLink>
                ))}

                {inLiveClass && <NavLink to="/live-class">Class Board</NavLink>}
              </Box>
            </Stack>
          </Box>
        </Slide>
      </Container>
    </Box>
  )
}
