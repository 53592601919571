import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { MdInfo } from 'react-icons/md'

import { useSocket } from 'context/socket-context'
import useAuthStore from 'store/useAuthStore'

const NoGameForRoundModal = ({}) => {
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useAuthStore((state) => state.user)
  const socket = useSocket()

  useEffect(() => {
    socket.on('oddPlayerOut', (userId) => {
      if (user?.userId === userId) {
        onOpen()
        history.push('/watch')
      }
    })

    return () => {
      socket.off('oddPlayerOut')
    }
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent overflow="hidden">
        <ModalHeader color="orange.500" bg="orange.100" fontWeight="extrabold">
          <Flex align="center">
            <MdInfo /> <Text ml={2}>Tournament Round Bye</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton color="orange.300" mt={2} />
        <ModalBody py={8} px={2} bg="orange.50" lineHeight={1.2}>
          <Text ml={2} color="orange.500" fontSize="lg" fontWeight="semibold">
            Due to an uneven number of players in the tournament you have been
            given a bye this round.
          </Text>
          <Text
            ml={2}
            color="orange.500"
            fontSize="lg"
            fontWeight="semibold"
            mt={4}
          >
            You will be awarded 1 point.
          </Text>
        </ModalBody>
        <ModalFooter bg="orange.50">
          <Button onClick={onClose} colorScheme="orange">
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default NoGameForRoundModal
