import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  HStack,
  Tab,
  TabList,
  Tabs,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import { ImSearch } from 'react-icons/im'
import { HiStatusOnline } from 'react-icons/hi'

import useOnlinePlayerDrawer from 'store/useOnlinePlayerDrawer'
import useOnlineUsers from 'store/useOnlineUsers'

import Search from './Search'
import OnlinePlayers from './OnlinePlayers'
import ChallengeModal from '../challenges/ChallengeModal'

export default function PlayerDrawer() {
  const { isOpen, onClose } = useOnlinePlayerDrawer()
  const onlineCount = useOnlineUsers((state) => state.count)

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay />
        <DrawerContent bg="green.100" roundedLeft="2xl" overflow="hidden">
          <DrawerCloseButton mt={2} color="green.400" />
          <DrawerHeader textColor="green.600" px={8} fontSize="2xl">
            Players
          </DrawerHeader>
          <DrawerBody p={0}>
            <Tabs variant="unstyled" isFitted>
              <TabList>
                <Tab
                  _selected={{
                    boxShadow: 'none',
                    borderColor: 'orange.200',
                    color: 'orange.500',
                    bg: 'orange.100',
                  }}
                  color="green.500"
                  borderBottomWidth={2}
                  borderColor="blackAlpha.100"
                  roundedTop="2xl"
                  fontWeight="bold"
                  bg="blackAlpha.200"
                >
                  <HStack>
                    <HiStatusOnline /> <p> Online: {onlineCount}</p>
                  </HStack>
                </Tab>
                <Tab
                  _selected={{
                    boxShadow: 'none',
                    color: 'orange.500',
                    bg: 'orange.100',
                  }}
                  color="green.500"
                  fontWeight="bold"
                  borderBottomWidth={2}
                  borderColor="blackAlpha.100"
                  roundedTop="2xl"
                  bg="blackAlpha.200"
                >
                  <HStack>
                    <ImSearch /> <p>Search</p>
                  </HStack>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel p={0}>
                  <OnlinePlayers closeDrawer={onClose} />
                </TabPanel>
                <TabPanel p={0}>
                  <Search closeDrawer={onClose} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <ChallengeModal />
    </>
  )
}
