import axios from 'lib/axios'
import toPollyString from 'utils/toPollyString'

async function convertTextToAudio(text) {
  try {
    const response = await axios.post(
      'https://staging.admin.mcqs.com/api/polly',
      {
        input: {
          OutputFormat: 'mp3',
          VoiceId: 'Justin',
          Text: toPollyString(text),
          TextType: 'ssml',
        },
      },
    )
    const url = convertAudioToUrlObject(response.data.AudioStream.data)
    return url
  } catch (err) {
    console.error('Error getting audio', err.message)
  }
}

function convertAudioToUrlObject(data) {
  const uInt8Array = new Uint8Array(data)
  const arrayBuffer = uInt8Array.buffer
  const blob = new Blob([arrayBuffer])
  const url = URL.createObjectURL(blob)
  return url
}

function dispose(url) {
  URL.revokeObjectURL(url)
}

const audioUtils = {
  textToAudio: convertTextToAudio,
  dispose,
}

export default audioUtils
