import { Suspense, useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { Canvas } from '@react-three/fiber'
import { PresentationControls } from '@react-three/drei'
import HouseWithPenguin from './HouseWithPenguin'

const needsPolyfill = 'ResizeObserver' in window === false

const Scene = () => {
  const [isLoading, setIsLoading] = useState(needsPolyfill)

  useEffect(() => {
    if (isLoading) {
      async function loadPollyFill() {
        const module = await import('@juggle/resize-observer')
        window.ResizeObserver = module.ResizeObserver
        setIsLoading(false)
      }

      loadPollyFill()
    }
  }, [isLoading])

  if (isLoading) {
    return <Box h="2xl" w="full" />
  }

  return (
    <Box h="2xl" w="full">
      <Suspense fallback={null}>
        <Canvas camera={{ fov: 70, position: [1, 0, 29] }}>
          <ambientLight />
          <pointLight position={[11, 10, 10]} />
          {/* <axesHelper args={[15]} /> */}
          <PresentationControls
            enabled={true}
            global={false}
            cursor={true}
            snap={true}
            speed={1}
            zoom={1.2}
            rotation={[0, 0, 0]}
            // polar={[0, Math.PI / 3]} // Vertical limits
            azimuth={[-Infinity, Infinity]} // Horizontal limits
            config={{ mass: 1, tension: 170, friction: 26 }}
          >
            <HouseWithPenguin />
          </PresentationControls>
        </Canvas>
      </Suspense>
    </Box>
  )
}

export default Scene
