import ReactDOM from 'react-dom'
import { Box, Flex, Progress } from '@chakra-ui/react'

import classes from './LoadingScreen.module.css'

const ProgressLoader = () => (
  <Progress width="300px" size="xs" bg="blackAlpha.300" isIndeterminate />
)

export const SpinningKnightLoader = () => (
  <Box
    className={classes.loader}
    h={24}
    w={24}
    m={8}
    transition="5s"
    _hover={{
      transform: 'rotate(1080deg)',
    }}
  >
    <img src={'/images/pieces/w-n.png'} alt={'white knight loader'} />
  </Box>
)

const LoadingScreen = ({ showPiece }) => {
  return ReactDOM.createPortal(
    <Box
      position="fixed"
      top={0}
      left={0}
      bottom={0}
      right={0}
      zIndex={99999}
      bg="blackAlpha.400"
    >
      <Flex justify="center" align="center" h="100vh">
        {showPiece ? <SpinningKnightLoader /> : <ProgressLoader />}
      </Flex>
    </Box>,
    document.getElementById('overlay'),
  )
}

export default LoadingScreen
