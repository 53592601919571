import Auth from 'components/Guards/Auth'
import Routes from './Routes'

import ZoomMountEl from 'components/ZoomSdkElement/ZoomMountElements'
import { Box } from '@chakra-ui/react'

function App() {
  return (
    <Auth>
      <Routes />
      <ZoomMountEl />
    </Auth  >
    )
}

export default App
