import { Link } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { HiOutlineMail } from 'react-icons/hi'

import { useUnreadCount } from 'hooks/conversations'

export default function Messages() {
  const { data } = useUnreadCount()

  return (
    <Link to="/messages">
      <Box
        ml={2}
        bg="blackAlpha.100"
        _hover={{ bg: 'blackAlpha.400' }}
        _focus={{ bg: 'blackAlpha.400' }}
        color="white"
        p={2}
        rounded="full"
        position="relative"
      >
        <HiOutlineMail size={22} />
        {data?.unreadCount > 0 && (
          <Box
            position="absolute"
            top={-2}
            right={-2}
            bg="green.500"
            rounded="full"
            px={1.5}
            py={1}
            textAlign="center"
            color="white"
            fontWeight="bold"
            fontSize="xs"
            lineHeight={1}
          >
            {data?.unreadCount}
          </Box>
        )}
      </Box>
    </Link>
  )
}
