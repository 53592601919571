import { Box, Flex, Icon, Tooltip } from '@chakra-ui/react'
import { FaChessKing } from 'react-icons/fa'

const boxProps = {
  random: {
    bgGradient: 'linear(to-t, #424242 50%, #a8a8a8 50%)',
    transform: 'rotate(90deg)',
  },
  white: { bg: 'blackAlpha.800' },
  black: { bg: 'whiteAlpha.800' },
}

const iconProps = {
  random: { color: 'whiteAlpha.500' },
  white: { color: 'whiteAlpha.900' },
  black: { color: 'blackAlpha.800' },
}

export default function ChallengeColorIcon({
  color = 'random',
  h = 10,
  w = 10,
  iconSize = 7,
  tooltip,
  ...rest
}) {
  const label = tooltip ? `You play as ${color}` : undefined

  return (
    <Tooltip label={label}>
      <Flex
        position="relative"
        justify="center"
        align="center"
        w="min"
        {...rest}
      >
        <Box
          mx="auto"
          h={h}
          w={w}
          rounded="50%"
          borderWidth={2}
          {...boxProps[color]}
        ></Box>
        <Icon
          boxSize={iconSize}
          position="absolute"
          as={FaChessKing}
          color="whiteAlpha.600"
          {...iconProps[color]}
        />
      </Flex>
    </Tooltip>
  )
}
